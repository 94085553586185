import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { atomToken } from '../state/atoms';

const useApp = () => {
    const {
        getAccessTokenSilently,
        loginWithRedirect,
        isAuthenticated,
        isLoading,
    } = useAuth0();

    const setAtomToken = useSetRecoilState(atomToken);

    const setToken = useCallback(async () => {
        if (isLoading) return;
        try {
            const token = await getAccessTokenSilently();
            setAtomToken(`Bearer ${token}`);
        } catch (error) {
            console.error(error);
            loginWithRedirect();
        }
    }, [getAccessTokenSilently, loginWithRedirect, setAtomToken, isLoading]);

    useEffect(() => {
        setToken();
    }, [setToken]);

    return {
        isAuthenticated,
    };
};

const Hook = {
    useApp,
};

export default Hook;
