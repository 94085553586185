import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';

import Hook, { IUseTableProps } from './useTable';
export interface ITableComponentProps extends IUseTableProps {
    rowHeight: number;
    rowsPerPageOptions: number[];
    count: number;
}

function TableComponent(props: ITableComponentProps) {
    const {
        rowHeight,
        rowsPerPageOptions,
        count,
        columnHeaders,
        sortableColumnHeaderIDs,
        rowObjects,
        tableState,
        onChangeTableState,
    } = props;

    const useTableProps = {
        columnHeaders: columnHeaders,
        sortableColumnHeaderIDs: sortableColumnHeaderIDs,
        rowObjects: rowObjects,
        tableState: tableState,
        onChangeTableState: onChangeTableState,
    };

    const { onChangeSort, onChangePage, onChangeRowsPerPage } =
        Hook.useTable(useTableProps);

    const tableHeader = () => {
        return (
            <TableHead data-testid="table-header">
                <TableRow>
                    {columnHeaders.map(columnHeader => (
                        <TableCell
                            align="center"
                            padding="normal"
                            key={columnHeader.id}
                            sortDirection={
                                tableState.sortHeader === columnHeader.id
                                    ? tableState.sortDirection
                                    : undefined
                            }
                        >
                            <TableSortLabel
                                active={
                                    tableState.sortHeader === columnHeader.id
                                }
                                direction={
                                    tableState.sortHeader === columnHeader.id
                                        ? tableState.sortDirection
                                        : undefined
                                }
                                onClick={onChangeSort}
                                data-testid={`sortable-column-header-${columnHeader.id}`}
                                data-columnid={columnHeader.id}
                            >
                                {columnHeader.label}
                                {tableState.sortHeader === columnHeader.id ? (
                                    <Box
                                        component="span"
                                        sx={visuallyHidden}
                                        data-testid={`sortable-column-header-direction-${columnHeader.id}`}
                                    >
                                        {tableState.sortDirection === 'asc'
                                            ? 'sorted ascending'
                                            : 'sorted descending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    const tableBody = () => {
        const emptyRows = tableState.numRows
            ? tableState.numRows - rowObjects.length
            : 0;
        return (
            <TableBody data-testid="table-body">
                {rowObjects.map((row, index) => {
                    return (
                        <TableRow
                            data-testid={`table-row-${index}`}
                            key={index}
                        >
                            {columnHeaders.map(columnHeader => (
                                <TableCell
                                    align="center"
                                    padding="normal"
                                    data-testid={`table-cell-${columnHeader.id}-${index}`}
                                >
                                    {row[columnHeader.id]}
                                </TableCell>
                            ))}
                        </TableRow>
                    );
                })}
                {emptyRows > 0 && (
                    <TableRow
                        style={{
                            height: rowHeight * emptyRows,
                        }}
                    >
                        <TableCell colSpan={columnHeaders.length} />
                    </TableRow>
                )}
            </TableBody>
        );
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%' }}>
                <TableContainer>
                    <Table>
                        {tableHeader()}
                        {tableBody()}
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={count}
                    rowsPerPage={tableState.numRows || 10}
                    page={tableState.pageNumber || 0}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}

export default TableComponent;
