import _ from 'lodash';
import { structs_ErrorResponse } from '../../api-autogenerated';
import { TTableArgs } from '../../typescript/ITCommon';

function isError(response: any): response is structs_ErrorResponse {
    return (response as structs_ErrorResponse).error !== undefined;
}

function tableStateReducer(
    state: TTableArgs,
    action: { type: 'setState'; payload: TTableArgs }
) {
    let newState;
    switch (action.type) {
        case 'setState':
            newState = action.payload;
            return _.isEqual(state, newState) ? state : newState;
        default:
            return state;
    }
}

const provinceStateOptions = [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Northwest Territories',
    'Nova Scotia',
    'Nunanvut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon',
];

const generalUtil = {
    isError,
    tableStateReducer,
    provinceStateOptions,
};
export default generalUtil;
