import { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import helper from './addEditMunicipalityModalHelper';
import { useRecoilValue } from 'recoil';
import { atomToken } from '../../../../state/atoms';
import { SelectChangeEvent } from '@mui/material';

export interface IUseAddEditMunicipalityModalProps {
    handleCloseModal: (_: React.MouseEvent<HTMLElement>) => void;
    municipalityId: number | null;
    municipalityName: string;
    province_state?: string;
}

function useAddEditMunicipalityModal(props: IUseAddEditMunicipalityModalProps) {
    const {
        handleCloseModal,
        municipalityId,
        municipalityName,
        province_state,
    } = props;
    const { t } = useTranslation();
    const [addEditModalState, dispatchAddEditModalState] = useReducer(
        helper.addEditModalStateReducer,
        {
            title: null,
            municipalityID: null,
            municipalityName: '',
            province_state: '',
        }
    );
    const [createFromCSV, setCreateFromCSV] = useState(false);
    const [csvFileState, dispatchCSVFileState] = useReducer(
        helper.csvFileReducer,
        {
            file: null,
            fileName: '',
        }
    );
    const [loading, setLoading] = useState(false);
    const token = useRecoilValue(atomToken);

    // Props

    // State

    // Callbacks
    const handleChangeName = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            dispatchAddEditModalState({
                type: 'setName',
                payload: { municipalityName: event.target.value },
            });
        },
        [dispatchAddEditModalState]
    );

    const handleClickConfirm = useCallback(
        async (event: React.MouseEvent<HTMLElement>) => {
            setLoading(true);
            const result = await helper.handleSave(
                token,
                addEditModalState.municipalityID,
                addEditModalState.municipalityName,
                csvFileState.file,
                addEditModalState.province_state
            );
            setLoading(false);
            if (result) {
                handleCloseModal(event);
            }
        },
        [
            setLoading,
            token,
            addEditModalState,
            csvFileState.file,
            handleCloseModal,
        ]
    );

    const handleToggleCSVCheckbox = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setCreateFromCSV(event.target.checked);
            dispatchCSVFileState({ type: 'clear' });
        },
        [setCreateFromCSV]
    );

    const handleAttachCSVFile = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files && event.target.files.length > 0) {
                dispatchCSVFileState({
                    type: 'setFile',
                    payload: { file: event.target.files[0] },
                });
            }
        },
        [dispatchCSVFileState]
    );

    const handleChangeProvinceState = useCallback(
        (event: SelectChangeEvent<string>): void => {
            dispatchAddEditModalState({
                type: 'setProvinceState',
                payload: { province_state: event.target.value },
            });
        },
        []
    );

    // UseEffects
    useEffect(() => {
        dispatchAddEditModalState({
            type: 'setModalType',
            payload: {
                municipalityID: municipalityId,
                municipalityName,
                province_state,
            },
        });
    }, [
        dispatchAddEditModalState,
        municipalityId,
        municipalityName,
        province_state,
    ]);

    // Return
    return {
        t,
        createFromCSV,
        addEditModalState,
        csvFileState,
        loading,
        handleChangeName,
        handleClickConfirm,
        handleToggleCSVCheckbox,
        handleAttachCSVFile,
        handleChangeProvinceState,
    };
}

const Hook = {
    useAddEditMunicipalityModal,
};

export default Hook;
