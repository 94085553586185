import { Box, Button, Container, Grid, Modal, Typography } from '@mui/material';
import { IUseDeleteMunicipalityModalProps } from './useDeleteMunicipalityModal';
import Hook from './useDeleteMunicipalityModal';

interface IDeleteMunicipalityModalProps
    extends IUseDeleteMunicipalityModalProps {
    municipalityName: string;
}

function DeleteMunicipalityModal(props: IDeleteMunicipalityModalProps) {
    const { handleCloseModal, municipalityId, municipalityName } = props;
    const useDeleteMunicipalityModalProps = {
        handleCloseModal,
        municipalityId,
    };
    const { handleClickConfirm } = Hook.useDeleteMunicipalityModal(
        useDeleteMunicipalityModalProps
    );

    return (
        <Modal open={true} onClose={handleCloseModal}>
            <Container maxWidth="sm">
                <Box bgcolor={'background.paper'}>
                    <Grid container padding={4}>
                        <Grid item xs={3}></Grid>
                        <Grid
                            item
                            xs={6}
                            display={'flex'}
                            justifyContent={'center'}
                        >
                            <Typography variant="h5">
                                {'Delete Municipality'}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            display={'flex'}
                            justifyContent={'flex-end'}
                        >
                            <Button onClick={handleCloseModal}> X </Button>
                        </Grid>
                        <Typography variant="h6">
                            {`Are you sure you want to delete ${municipalityName}?`}
                        </Typography>
                        <Button onClick={handleClickConfirm}> Confirm </Button>
                    </Grid>
                </Box>
            </Container>
        </Modal>
    );
}

export default DeleteMunicipalityModal;
