import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import helper from './addEditGoalModalHelper';
import { useRecoilValue } from 'recoil';
import { atomMunicipalityID } from '../../../../state/atoms';
import { SelectChangeEvent } from '@mui/material';

export interface IUseAddEditGoalModalProps {
    handleCloseModal: (_: React.MouseEvent<HTMLElement>) => void;
    goalID: number | null;
    goalDescription: string;
    goalColor: string;
}

function useAddEditGoalModal(props: IUseAddEditGoalModalProps) {
    const { handleCloseModal, goalID, goalDescription, goalColor } = props;
    const { t } = useTranslation();
    const [addEditModalState, dispatchAddEditModalState] = useReducer(
        helper.addEditModalStateReducer,
        {
            title: null,
            goalID: null,
            goalDescription: '',
            goalColor: '',
        }
    );
    const municipalityID = useRecoilValue(atomMunicipalityID);

    // Callbacks
    const handleChangeGoalDescription = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            dispatchAddEditModalState({
                type: 'setGoalDescription',
                payload: { goalDescription: event.target.value },
            });
        },
        [dispatchAddEditModalState]
    );

    const handleClickConfirm = useCallback(
        async (event: React.MouseEvent<HTMLElement>) => {
            const result = await helper.handleSave(
                goalID,
                addEditModalState.goalDescription,
                addEditModalState.goalColor,
                municipalityID
            );
            if (result) {
                handleCloseModal(event);
            }
        },
        [
            goalID,
            addEditModalState.goalDescription,
            addEditModalState.goalColor,
            municipalityID,
            handleCloseModal,
        ]
    );

    const handleChangeGoalColor = useCallback(
        (event: SelectChangeEvent<string>) => {
            dispatchAddEditModalState({
                type: 'setGoalColor',
                payload: { goalColor: event.target.value },
            });
        },
        [dispatchAddEditModalState]
    );

    // UseEffects
    useEffect(() => {
        dispatchAddEditModalState({
            type: 'setModalType',
            payload: { goalID, goalDescription, goalColor },
        });
    }, [dispatchAddEditModalState, goalID, goalDescription, goalColor]);

    // Return
    return {
        t,
        addEditModalState,
        handleChangeGoalDescription,
        handleClickConfirm,
        handleChangeGoalColor,
    };
}

const Hook = {
    useAddEditGoalModal,
};

export default Hook;
