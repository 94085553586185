import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import helper from './deleteGoalModalHelper';
import { useRecoilValue } from 'recoil';
import { atomMunicipalityID } from '../../../../state/atoms';

export interface IUseDeleteGoalModalProps {
    handleCloseModal: (_: React.MouseEvent<HTMLElement>) => void;
    goalID: number | null;
}

function useDeleteGoalModal(props: IUseDeleteGoalModalProps) {
    const { handleCloseModal, goalID } = props;
    const { t } = useTranslation();
    const municipalityID = useRecoilValue(atomMunicipalityID);

    // Props

    // State

    // Callbacks

    const handleClickConfirm = useCallback(
        async (event: React.MouseEvent<HTMLElement>) => {
            const result = await helper.confirmDelete(goalID, municipalityID);
            if (result) {
                handleCloseModal(event);
            }
        },
        [goalID, municipalityID, handleCloseModal]
    );

    // UseEffects

    // Return
    return {
        t,
        handleClickConfirm,
    };
}

const Hook = {
    useDeleteGoalModal,
};

export default Hook;
