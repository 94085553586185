import Hook, { IUseMunicipalitiesTableProps } from './useMunicipalitiesTable';
import TableComponent from '../../../components/Table/TableComponent';
import { Button, Grid, TextField } from '@mui/material';
import PencilIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import AddEditMunicipalityModal from './AddEditMunicipalityModal/AddEditMunicipalityModal';
import DeleteMunicipalityModal from './DeleteMunicipalityModal/DeleteMunicipalityModal';
import { Link } from 'react-router-dom';

export interface IMunicipalitiesTableProps
    extends IUseMunicipalitiesTableProps {}

function MunicipalitiesTable() {
    const useAdminMunicipalitiesPageProps = {};
    const {
        tableState,
        tableData,
        modalState,
        handleOpenAddModal,
        handleOpenEditModal,
        handleOpenDeleteModal,
        handleCloseModal,
        handleChangeTableState,
        handleChangeSearchQuery,
    } = Hook.useMunicipalitiesTable(useAdminMunicipalitiesPageProps);

    const renderTableRows = () => {
        const rows = [];
        for (let i = 0; i < tableData.rows.length; i++) {
            const row = tableData.rows[i];
            rows.push({
                id: row.id,
                name: row.name,
                province_state: row.province_state,
                viewButton: (
                    <Grid container>
                        <Grid item>
                            <Link to={`/municipality?id=${row.id}`}>
                                <Button id={`${row.id}`}>{'View'}</Button>
                            </Link>
                        </Grid>
                    </Grid>
                ),
                editButton: (
                    <Grid container>
                        <Grid item>
                            <Button
                                onClick={handleOpenEditModal}
                                id={`${row.id}`}
                                data-name={row.name}
                                data-province_state={row.province_state}
                            >
                                <PencilIcon />
                            </Button>
                        </Grid>
                    </Grid>
                ),
                deleteButton: (
                    <Grid container>
                        <Grid item>
                            <Button
                                onClick={handleOpenDeleteModal}
                                id={`${row.id}`}
                                data-name={row.name}
                            >
                                {' '}
                                <DeleteIcon />
                            </Button>
                        </Grid>
                    </Grid>
                ),
            });
        }
        return rows;
    };

    const renderModal = () => {
        if (modalState.type === 'Add_Edit') {
            return (
                <AddEditMunicipalityModal
                    handleCloseModal={handleCloseModal}
                    municipalityId={modalState.municipalityID}
                    municipalityName={modalState.municipalityName}
                    province_state={modalState.province_state}
                />
            );
        } else if (modalState.type === 'Delete') {
            return (
                <DeleteMunicipalityModal
                    handleCloseModal={handleCloseModal}
                    municipalityId={modalState.municipalityID}
                    municipalityName={modalState.municipalityName}
                />
            );
        }
        return <></>;
    };

    return (
        <Grid container>
            <Grid item xs={6} display="flex" justifyContent="flex-start">
                <Button onClick={handleOpenAddModal}>
                    {' '}
                    + Add Municipality{' '}
                </Button>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-end">
                <TextField
                    fullWidth
                    label={'Search'}
                    onChange={handleChangeSearchQuery}
                />
            </Grid>
            <Grid item xs={12}>
                <TableComponent
                    rowHeight={50}
                    rowsPerPageOptions={[5, 10, 25]}
                    count={tableData.count}
                    columnHeaders={[
                        { id: 'id', label: 'ID' },
                        { id: 'name', label: 'Name' },
                        { id: 'province_state', label: 'Province/State' },
                        { id: 'viewButton', label: '' },
                        { id: 'editButton', label: '' },
                        { id: 'deleteButton', label: '' },
                    ]}
                    sortableColumnHeaderIDs={['id', 'name', 'province_state']}
                    rowObjects={renderTableRows()}
                    tableState={tableState}
                    onChangeTableState={handleChangeTableState}
                />
            </Grid>
            {renderModal()}
        </Grid>
    );
}

export default MunicipalitiesTable;
