import _ from 'lodash';
import {
    DefaultService,
    databaseModels_MunicipalityCustomer,
} from '../../../api-autogenerated';
import { TTableArgs } from '../../../typescript/ITCommon';
import generalUtil from '../../../utils/generalUtil/generalUtil';

const modalStateReducer = (
    state: {
        municipalityID: number | null;
        municipalityName: string;
        province_state?: string;
        type: 'Add_Edit' | 'Delete' | null;
    },
    action:
        | { type: 'openAddModal' }
        | {
              type: 'openEditModal';
              payload: {
                  municipalityID: number;
                  municipalityName: string;
                  province_state?: string;
              };
          }
        | {
              type: 'openDeleteModal';
              payload: { municipalityID: number; municipalityName: string };
          }
        | { type: 'closeModal' }
): {
    municipalityID: number | null;
    municipalityName: string;
    type: 'Add_Edit' | 'Delete' | null;
    province_state?: string;
} => {
    let newState;
    switch (action.type) {
        case 'openAddModal':
            newState = {
                municipalityID: null,
                municipalityName: '',
                type: 'Add_Edit' as const,
                province_state: '',
            };
            return _.isEqual(newState, state) ? state : newState;
        case 'openEditModal':
            newState = {
                municipalityID: action.payload.municipalityID,
                municipalityName: action.payload.municipalityName,
                type: 'Add_Edit' as const,
                province_state: action.payload.province_state,
            };
            return _.isEqual(newState, state) ? state : newState;
        case 'openDeleteModal':
            newState = {
                ...state,
                municipalityID: action.payload.municipalityID,
                municipalityName: action.payload.municipalityName,
                type: 'Delete' as const,
            };
            return _.isEqual(newState, state) ? state : newState;
        case 'closeModal':
            newState = {
                municipalityID: null,
                municipalityName: '',
                type: null,
                province_state: '',
            };
            return _.isEqual(newState, state) ? state : newState;
        default:
            return state;
    }
};

const tableDataReducer = (
    state: {
        rows: databaseModels_MunicipalityCustomer[];
        count: number;
    },
    action: {
        type: 'setState';
        payload: {
            rows: databaseModels_MunicipalityCustomer[];
            count: number;
        };
    }
): {
    rows: databaseModels_MunicipalityCustomer[];
    count: number;
} => {
    let newState;
    switch (action.type) {
        case 'setState':
            newState = action.payload;
            return _.isEqual(newState, state) ? state : newState;
        default:
            return state;
    }
};

const tableLoad = async (
    token: string | null,
    args: TTableArgs
): Promise<{
    rows: databaseModels_MunicipalityCustomer[];
    count: number;
    error: string | undefined;
}> => {
    const newTableData: {
        rows: databaseModels_MunicipalityCustomer[];
        count: number;
        error: string | undefined;
    } = {
        rows: [] as databaseModels_MunicipalityCustomer[],
        count: 0,
        error: undefined,
    };
    return new Promise(async (resolve, _) => {
        if (!token) {
            resolve({ rows: [], count: 0, error: 'Error: token is null' });
            return;
        }
        try {
            const tableDataReturn =
                await DefaultService.getAdminMunicipalitiesPageMunicipalitiesTable(
                    token,
                    args.numRows,
                    args.pageNumber,
                    args.searchQuery,
                    args.sortDirection,
                    args.sortHeader
                );
            if (generalUtil.isError(tableDataReturn)) {
                resolve({ rows: [], count: 0, error: tableDataReturn.error });
            } else if (
                !tableDataReturn.municipalities ||
                !tableDataReturn.count
            ) {
                if (
                    tableDataReturn.municipalities === undefined ||
                    tableDataReturn.count === undefined
                ) {
                    newTableData.error =
                        'Error: tableDataReturn.municipalities or tableDataReturn.count is undefined';
                }
                newTableData.rows = [];
                newTableData.count = 0;
            } else {
                newTableData.rows = tableDataReturn.municipalities;
                newTableData.count = tableDataReturn.count;
            }
            resolve(newTableData);
        } catch (e: unknown) {
            console.log('Error in tableLoad', e);
            resolve({ rows: [], count: 0, error: 'Error in tableLoad' });
        }
    });
};

const helper = {
    modalStateReducer,
    tableDataReducer,
    tableLoad,
};

export default helper;
