import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { TTableArgs } from '../../../typescript/ITCommon';
import helper from './municipalitiesTableHelper';
import { databaseModels_MunicipalityCustomer } from '../../../api-autogenerated';
import generalUtil from '../../../utils/generalUtil/generalUtil';
import { debounce } from 'lodash';
import { useRecoilValue } from 'recoil';
import { atomToken } from '../../../state/atoms';

export interface IUseMunicipalitiesTableProps {}

function useMunicipalitiesTable(props: IUseMunicipalitiesTableProps) {
    const { t } = useTranslation();
    const [tableState, dispatchTableState] = useReducer(
        generalUtil.tableStateReducer,
        {
            pageNumber: 0,
            numRows: 5,
            searchQuery: undefined,
            sortHeader: 'id',
            sortDirection: 'asc',
        } as TTableArgs
    );
    const [tableData, dispatchTableData] = useReducer(helper.tableDataReducer, {
        rows: [] as databaseModels_MunicipalityCustomer[],
        count: 0,
    });
    const [modalState, dispatchModalState] = useReducer(
        helper.modalStateReducer,
        {
            municipalityID: null,
            municipalityName: '',
            province_state: '',
            type: null,
        }
    );
    const token = useRecoilValue(atomToken);

    // Callbacks
    const loadTableData = useCallback(async () => {
        const { rows, count, error } = await helper.tableLoad(
            token,
            tableState
        );
        if (!error) {
            dispatchTableData({
                type: 'setState',
                payload: { rows, count },
            });
        }
    }, [token, tableState, dispatchTableData]);

    const handleOpenAddModal = useCallback(
        (_: React.MouseEvent<HTMLElement>) => {
            dispatchModalState({ type: 'openAddModal' });
        },
        [dispatchModalState]
    );

    const handleOpenEditModal = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            const municipalityID = Number(event.currentTarget.id);
            const municipalityName =
                event.currentTarget.getAttribute('data-name') || '';
            let province_state =
                event.currentTarget.getAttribute('data-province_state') ||
                undefined;
            dispatchModalState({
                type: 'openEditModal',
                payload: { municipalityID, municipalityName, province_state },
            });
        },
        [dispatchModalState]
    );

    const handleOpenDeleteModal = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            const municipalityID = Number(event.currentTarget.id);
            const municipalityName =
                event.currentTarget.getAttribute('data-name') || '';
            dispatchModalState({
                type: 'openDeleteModal',
                payload: { municipalityID, municipalityName },
            });
        },
        [dispatchModalState]
    );

    const handleCloseModal = useCallback(
        (_: React.MouseEvent<HTMLElement>) => {
            if (tableState.pageNumber !== 0) {
                const newTableState = {
                    ...tableState,
                    pageNumber: 0,
                } as TTableArgs;
                dispatchTableState({
                    type: 'setState',
                    payload: newTableState,
                });
            } else {
                loadTableData();
            }
            dispatchModalState({ type: 'closeModal' });
        },
        [dispatchModalState, tableState, dispatchTableState, loadTableData]
    );

    const handleChangeTableState = useCallback(
        async (newTableState: TTableArgs) => {
            dispatchTableState({ type: 'setState', payload: newTableState });
        },
        [dispatchTableState]
    );

    const debouncedSearch = useMemo(
        () =>
            debounce((searchQuery: string) => {
                dispatchTableState({
                    type: 'setState',
                    payload: {
                        ...tableState,
                        searchQuery,
                        pageNumber: 0,
                    },
                });
            }, 500),
        [dispatchTableState, tableState]
    );

    const handleChangeSearchQuery = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            debouncedSearch(event.target.value);
        },
        [debouncedSearch]
    );

    // UseEffects
    useEffect(() => {
        // Load table data on every tableState change
        loadTableData();
    }, [loadTableData]);

    // Return
    return {
        t,
        tableState,
        tableData,
        modalState,
        handleOpenAddModal,
        handleOpenEditModal,
        handleOpenDeleteModal,
        handleCloseModal,
        handleChangeTableState,
        handleChangeSearchQuery,
    };
}

const Hook = {
    useMunicipalitiesTable,
};

export default Hook;
