import { Box, Button, Container, Grid, Modal, Typography } from '@mui/material';
import { IUseDeleteStrategyModalProps } from './useDeleteStrategyModal';
import Hook from './useDeleteStrategyModal';

interface IDeleteStrategyModalProps extends IUseDeleteStrategyModalProps {
    strategyDescription: string;
}

function DeleteStrategyModal(props: IDeleteStrategyModalProps) {
    const { handleCloseModal, strategyID, strategyDescription } = props;
    const useDeleteStrategyModalProps = {
        handleCloseModal,
        strategyID,
    };
    const { handleClickConfirm } = Hook.useDeleteStrategyModal(
        useDeleteStrategyModalProps
    );

    return (
        <Modal open={true} onClose={handleCloseModal}>
            <Container maxWidth="sm">
                <Box bgcolor={'background.paper'}>
                    <Grid container padding={4}>
                        <Grid item xs={3}></Grid>
                        <Grid
                            item
                            xs={6}
                            display={'flex'}
                            justifyContent={'center'}
                        >
                            <Typography variant="h5">
                                {'Delete Strategy'}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            display={'flex'}
                            justifyContent={'flex-end'}
                        >
                            <Button onClick={handleCloseModal}> X </Button>
                        </Grid>
                        <Typography variant="h6">
                            {`Are you sure you want to delete ${strategyDescription}?`}
                        </Typography>
                        <Button onClick={handleClickConfirm}> Confirm </Button>
                    </Grid>
                </Box>
            </Container>
        </Modal>
    );
}

export default DeleteStrategyModal;
