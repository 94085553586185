import { GetColorName } from 'hex-color-to-color-name';
import { useCallback, useRef } from 'react';

function useColorSelect() {
    const colorNames = useRef<{ [key: string]: string | undefined }>({
        '#6C3483': 'Royal Purple',
        '#8E44AD': 'Vivid Purple',
        '#3498DB': 'Bright Blue',
        '#2874A6': 'Ocean Blue',
        '#1ABC9C': 'Turquoise',
        '#239B56': 'Forest Green',
        '#F1C40F': 'Bright Yellow',
        '#E67E22': 'Pumpkin Orange',
        '#CA6F1E': 'Copper Orange',
        '#CB4335': 'Red',
        '#A93226': 'Brick Red',
        '#78281F': 'Dark Red',
        '#FA8072': 'Salmon',
        '#FFC0CB': 'Pink',
        '#000000': 'Black',
    });

    const isInColorNames = useCallback((color: string) => {
        return colorNames.current[color] !== undefined;
    }, []);

    const getColorName = useCallback(
        (color: string) => {
            if (isInColorNames(color)) {
                return colorNames.current[color];
            }
            return GetColorName(color);
        },
        [isInColorNames]
    );

    return {
        isInColorNames,
        getColorName,
        colorOptions: Object.keys(colorNames.current),
    };
}

const Hook = {
    useColorSelect,
};

export default Hook;
