import { Button, Grid, TextField, Typography } from '@mui/material';
import PencilIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import Hook, { IUseStrategiesTabProps } from './useStrategiesTab';
import TableComponent from '../../../components/Table/TableComponent';
import AddEditStrategyModal from './AddEditStrategyModal/AddEditStrategyModal';
import DeleteStrategyModal from './DeleteStrategyModal/DeleteStrategyModal';

export interface IStrategiesTabProps extends IUseStrategiesTabProps {}

function StrategiesTab(props: IStrategiesTabProps) {
    const useStrategiesTabProps = {};
    const {
        tableState,
        tableData,
        modalState,
        handleOpenAddModal,
        handleOpenEditModal,
        handleOpenDeleteModal,
        handleCloseModal,
        handleChangeTableState,
        handleChangeSearchQuery,
    } = Hook.useStrategiesTab(useStrategiesTabProps);

    const renderModal = () => {
        if (modalState.type === 'Add_Edit') {
            return (
                <AddEditStrategyModal
                    handleCloseModal={handleCloseModal}
                    strategyID={modalState.strategyID}
                    strategyDescription={modalState.strategyDescription}
                    strategyColor={modalState.strategyColor}
                />
            );
        } else if (modalState.type === 'Delete') {
            return (
                <DeleteStrategyModal
                    handleCloseModal={handleCloseModal}
                    strategyID={modalState.strategyID}
                    strategyDescription={modalState.strategyDescription}
                />
            );
        }
        return <></>;
    };

    const renderTableRows = () => {
        const rows = [];
        for (let i = 0; i < tableData.rows.length; i++) {
            const row = tableData.rows[i];
            rows.push({
                id: row.id,
                strategy: (
                    <Grid container textAlign={'left'}>
                        <Grid
                            item
                            xs={2}
                            display="flex"
                            flexDirection={'column'}
                            justifyContent={'center'}
                        >
                            <Typography color={row.color}>#</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography>{row.strategy}</Typography>
                        </Grid>
                    </Grid>
                ),
                editButton: (
                    <Grid container>
                        <Grid item>
                            <Button
                                onClick={handleOpenEditModal}
                                id={`${row.id}`}
                                data-strategy={row.strategy}
                                data-color={row.color}
                            >
                                <PencilIcon />
                            </Button>
                        </Grid>
                    </Grid>
                ),
                deleteButton: (
                    <Grid container>
                        <Grid item>
                            <Button
                                onClick={handleOpenDeleteModal}
                                id={`${row.id}`}
                                data-strategy={row.strategy}
                                data-color={row.color}
                            >
                                {' '}
                                <DeleteIcon />
                            </Button>
                        </Grid>
                    </Grid>
                ),
            });
        }
        return rows;
    };

    return (
        <Grid container>
            <Grid item xs={6} display="flex" justifyContent="flex-start">
                <Button onClick={handleOpenAddModal}> + Add Strategy </Button>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-end">
                <TextField
                    fullWidth
                    label={'Search'}
                    onChange={handleChangeSearchQuery}
                />
            </Grid>
            <Grid item xs={12}>
                <TableComponent
                    rowHeight={50}
                    rowsPerPageOptions={[5, 10, 25]}
                    count={tableData.count}
                    columnHeaders={[
                        { id: 'id', label: 'ID' },
                        { id: 'strategy', label: 'Strategy' },
                        { id: 'editButton', label: '' },
                        { id: 'deleteButton', label: '' },
                    ]}
                    sortableColumnHeaderIDs={['id', 'strategy']}
                    rowObjects={renderTableRows()}
                    tableState={tableState}
                    onChangeTableState={handleChangeTableState}
                />
            </Grid>
            {renderModal()}
        </Grid>
    );
}

export default StrategiesTab;
