import { useTranslation } from 'react-i18next';

export interface IUseErrorPageProps {}

function useErrorPage(props: IUseErrorPageProps) {
    const { t } = useTranslation();
    // Props

    // Reducers

    // State

    // Callbacks

    // UseEffects

    // Return
    return {
        t,
    };
}

const Hook = {
    useErrorPage,
};

export default Hook;
