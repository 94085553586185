import _ from 'lodash';
import { DefaultService } from '../../../../api-autogenerated';

const addEditModalStateReducer = (
    state: {
        title: 'Add Municipality' | 'Edit Municipality' | null;
        municipalityID: number | null;
        municipalityName: string;
        province_state: string;
    },
    action:
        | {
              type: 'setModalType';
              payload: {
                  municipalityID: number | null;
                  municipalityName: string;
                  province_state?: string;
              };
          }
        | { type: 'setName'; payload: { municipalityName: string } }
        | { type: 'setProvinceState'; payload: { province_state?: string } }
): {
    title: 'Add Municipality' | 'Edit Municipality' | null;
    municipalityID: number | null;
    municipalityName: string;
    province_state: string;
} => {
    let newState;
    switch (action.type) {
        case 'setModalType':
            newState = {
                title: action.payload.municipalityID
                    ? ('Edit Municipality' as const)
                    : ('Add Municipality' as const),
                municipalityName: action.payload.municipalityName,
                municipalityID: action.payload.municipalityID,
                province_state: action.payload.province_state || '',
            };
            return _.isEqual(newState, state) ? state : newState;
        case 'setName':
            newState = {
                ...state,
                municipalityName: action.payload.municipalityName,
            };
            return _.isEqual(newState, state) ? state : newState;
        case 'setProvinceState':
            newState = {
                ...state,
                province_state: action.payload.province_state || '',
            };
            return _.isEqual(newState, state) ? state : newState;
        default:
            return state;
    }
};

const csvFileReducer = (
    state: {
        file: File | null;
        fileName: string;
    },
    action: { type: 'setFile'; payload: { file: File } } | { type: 'clear' }
): { file: File | null; fileName: string } => {
    let newState;
    switch (action.type) {
        case 'setFile':
            //Truncate file name if it is too long
            let fileName = action.payload.file.name;
            if (fileName.length > 10) {
                fileName = fileName.substring(0, 10) + '...';
            }
            newState = {
                file: action.payload.file,
                fileName: fileName,
            };
            return _.isEqual(newState, state) ? state : newState;
        case 'clear':
            newState = {
                file: null,
                fileName: '',
            };
            return _.isEqual(newState, state) ? state : newState;
        default:
            return state;
    }
};

const handleSave = async (
    token: string | null,
    municipalityID: number | null,
    municipalityName: string,
    csvFile: File | null,
    province_state?: string
) => {
    return new Promise<boolean>(async (resolve, _) => {
        if (!token) {
            resolve(false);
            return;
        }
        if (municipalityID) {
            try {
                await DefaultService.postAdminMunicipalitiesPageUpdateMunicipality(
                    {
                        id: municipalityID,
                        name: municipalityName,
                        province_state: province_state,
                    },
                    token
                );
                resolve(true);
            } catch (error) {
                console.error(error);
                resolve(false);
            }
        } else {
            if (!csvFile) {
                try {
                    await DefaultService.postAdminMunicipalitiesPageAddMunicipality(
                        {
                            name: municipalityName,
                            province_state: province_state,
                        },
                        token
                    );
                    resolve(true);
                } catch (error) {
                    console.error(error);
                    resolve(false);
                }
            } else {
                try {
                    await DefaultService.postAdminMunicipalitiesPageAddMunicipalityCsv(
                        csvFile,
                        municipalityName,
                        token,
                        province_state
                    );
                    resolve(true);
                } catch (error) {
                    console.error(error);
                    resolve(false);
                }
            }
        }
    });
};

const helper = {
    addEditModalStateReducer,
    csvFileReducer,
    handleSave,
};

export default helper;
