import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Importing translation files

import translationEN from './lang/en.json';
import translationFR from './lang/fr.json';

//Creating object with the variables of imported translation files
const resources = {
    en: {
        translation: translationEN,
    },
    fr: {
        translation: translationFR,
    },
};

//i18N Initialization

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: true,
        resources,
        keySeparator: ':',
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: ['navigator'],
        },
    });

export default i18n;
