import { Box, Button, Container, Grid, Typography } from '@mui/material';
import Hook from './useAdminBackBar';
import { Link } from 'react-router-dom';

function AdminBackBar() {
    const { backButtonClicked } = Hook.useAdminBackBar();
    return (
        <Box
            width="100%"
            bgcolor="primary.main"
            color="primary.contrastText"
            p={2}
        >
            <Container maxWidth="md">
                <Grid container>
                    <Grid item xs={3}>
                        <Link to="/" onClick={backButtonClicked}>
                            <Button variant="outlined">
                                <Typography
                                    variant="body1"
                                    color={'primary.contrastText'}
                                >
                                    {'< Back'}
                                </Typography>
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={6}>
                        <Box textAlign="center">
                            <Typography variant="h6">
                                You are viewing a customer view
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default AdminBackBar;
