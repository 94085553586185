import {
    databaseModels_MunicipalityStrategy,
    DefaultService,
} from '../../../api-autogenerated';
import _ from 'lodash';
import { TTableArgs } from '../../../typescript/ITCommon';
import generalUtil from '../../../utils/generalUtil/generalUtil';

const modalStateReducer = (
    state: {
        strategyID: number | null;
        strategyDescription: string;
        strategyColor: string;
        type: 'Add_Edit' | 'Delete' | null;
    },
    action:
        | { type: 'openAddModal' }
        | {
              type: 'openEditModal';
              payload: {
                  strategyID: number;
                  strategyDescription: string;
                  strategyColor: string;
              };
          }
        | {
              type: 'openDeleteModal';
              payload: { strategyID: number; strategyDescription: string };
          }
        | { type: 'closeModal' }
): {
    strategyID: number | null;
    strategyDescription: string;
    strategyColor: string;
    type: 'Add_Edit' | 'Delete' | null;
} => {
    let newState;
    switch (action.type) {
        case 'openAddModal':
            newState = {
                strategyID: null,
                strategyDescription: '',
                strategyColor: '',
                type: 'Add_Edit' as const,
            };
            return _.isEqual(newState, state) ? state : newState;
        case 'openEditModal':
            newState = {
                strategyID: action.payload.strategyID,
                strategyDescription: action.payload.strategyDescription,
                strategyColor: action.payload.strategyColor,
                type: 'Add_Edit' as const,
            };
            return _.isEqual(newState, state) ? state : newState;
        case 'openDeleteModal':
            newState = {
                ...state,
                strategyID: action.payload.strategyID,
                strategyDescription: action.payload.strategyDescription,
                type: 'Delete' as const,
            };
            return _.isEqual(newState, state) ? state : newState;
        case 'closeModal':
            newState = {
                strategyID: null,
                strategyDescription: '',
                strategyColor: '',
                type: null,
            };
            return _.isEqual(newState, state) ? state : newState;
        default:
            return state;
    }
};

const tableDataReducer = (
    state: {
        rows: databaseModels_MunicipalityStrategy[];
        count: number;
    },
    action: {
        type: 'setState';
        payload: {
            rows: databaseModels_MunicipalityStrategy[];
            count: number;
        };
    }
): {
    rows: databaseModels_MunicipalityStrategy[];
    count: number;
} => {
    let newState;
    switch (action.type) {
        case 'setState':
            newState = action.payload;
            return _.isEqual(newState, state) ? state : newState;
        default:
            return state;
    }
};

const tableLoad = async (
    municipalityID: number,
    args: TTableArgs
): Promise<{
    rows: databaseModels_MunicipalityStrategy[];
    count: number;
    error: string | undefined;
}> => {
    const newTableData = {
        rows: [] as databaseModels_MunicipalityStrategy[],
        count: 0,
        error: undefined,
    };

    return new Promise(async (resolve, _) => {
        try {
            const tableDataReturn =
                await DefaultService.getMunicipalityDashboardPageStrategiesTable(
                    municipalityID,
                    args.numRows,
                    args.pageNumber,
                    args.searchQuery,
                    args.sortDirection,
                    args.sortHeader
                );
            if (generalUtil.isError(tableDataReturn)) {
                resolve({ rows: [], count: 0, error: tableDataReturn.error });
            } else if (!tableDataReturn.strategies || !tableDataReturn.count) {
                resolve({
                    rows: [],
                    count: 0,
                    error: 'tableDataReturn.strategies or tableDataReturn.count is undefined',
                });
            } else {
                newTableData.rows = tableDataReturn.strategies;
                newTableData.count = tableDataReturn.count;
                resolve(newTableData);
            }
        } catch (error) {
            resolve({
                rows: [],
                count: 0,
                error: 'Error in tableLoad',
            });
        }
    });
};

const helper = {
    modalStateReducer,
    tableDataReducer,
    tableLoad,
};

export default helper;
