import { Location, NavigateFunction } from 'react-router-dom';

const addTabQuery = (
    location: Location,
    navigate: NavigateFunction,
    tab: number,
    replace: boolean = false
) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('tab', tab.toString());
    navigate({ search: searchParams.toString() }, { replace: replace });
};

const helper = {
    addTabQuery,
};

export default helper;
