import { DefaultService } from '../../../../api-autogenerated';

const confirmDelete = async (
    goalID: number | null,
    municipalityID: number | null
): Promise<boolean> => {
    if (!municipalityID || !goalID) {
        return new Promise<boolean>(resolve => resolve(false));
    }
    return new Promise((resolve, reject) => {
        try {
            DefaultService.deleteMunicipalityDashboardPageDeleteGoal({
                goalID,
                municipalityID,
            });
            resolve(true);
        } catch (error) {
            resolve(false);
        }
    });
};

const helper = { confirmDelete };

export default helper;
