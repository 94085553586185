import { ChangeEvent, MouseEvent, useCallback, useEffect } from 'react';

import {
    TTableArgs,
    TTableColumnHeaders,
    TTableColumnValues,
} from '../../typescript/ITCommon';
import helper from './tableHelper';

export interface IUseTableProps {
    columnHeaders: TTableColumnHeaders;
    sortableColumnHeaderIDs: string[];
    rowObjects: TTableColumnValues;
    tableState: TTableArgs;
    onChangeTableState: (args: TTableArgs) => void;
}

function useTable(props: IUseTableProps) {
    // Props
    const {
        columnHeaders,
        sortableColumnHeaderIDs,
        rowObjects,
        tableState,
        onChangeTableState,
    } = props;

    // Callbacks
    const handleOnChangeSort = useCallback(
        (event: MouseEvent<HTMLSpanElement>) => {
            const { sortHeader, sortDirection } = helper.handleOnChangeSort(
                event,
                tableState
            );
            onChangeTableState({
                ...tableState,
                sortHeader: sortHeader,
                sortDirection: sortDirection,
            });
        },
        [tableState, onChangeTableState]
    );

    const handleOnChangePage = useCallback(
        (_: unknown, newPage: number) => {
            onChangeTableState({ ...tableState, pageNumber: newPage });
        },
        [tableState, onChangeTableState]
    );

    const handleOnChangeRowsPerPage = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const newRowsPerPage = helper.handleOnChangeRowsPerPage(event);
            onChangeTableState({ ...tableState, numRows: newRowsPerPage });
        },
        [tableState, onChangeTableState]
    );

    const handleValidateTableData = useCallback(() => {
        helper.tableDataValidation(
            columnHeaders,
            sortableColumnHeaderIDs,
            rowObjects
        );
    }, [columnHeaders, sortableColumnHeaderIDs, rowObjects]);

    useEffect(() => {
        // Table Data Validation whenever columnHeaders, sortableColumnHeaderIDs, or rowsState change
        handleValidateTableData();
    }, [handleValidateTableData]);

    // Return
    return {
        onChangeSort: handleOnChangeSort,
        onChangePage: handleOnChangePage,
        onChangeRowsPerPage: handleOnChangeRowsPerPage,
    };
}

const Hook = {
    useTable,
};

export default Hook;
