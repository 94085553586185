import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import helper from './actionsTabHelper';
import generalUtil from '../../../utils/generalUtil/generalUtil';
import { TTableArgs } from '../../../typescript/ITCommon';
import { endpoints_MunicipalityDashboardPage_ActionsTableResponse } from '../../../api-autogenerated';
import { useRecoilValue } from 'recoil';
import { atomMunicipalityID } from '../../../state/atoms';
import { useLocation, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

export interface IUseActionsTabProps {}

function useActionsTab(props: IUseActionsTabProps) {
    const { t } = useTranslation();
    const [tableState, dispatchTableState] = useReducer(
        generalUtil.tableStateReducer,
        {
            pageNumber: 0,
            numRows: 5,
            searchQuery: undefined,
            sortHeader: 'id',
            sortDirection: 'asc',
        } as TTableArgs
    );
    const [tableData, dispatchTableData] = useReducer(helper.tableDataReducer, {
        rows: [] as endpoints_MunicipalityDashboardPage_ActionsTableResponse['actions'],
        count: 0,
    });
    const [modalState, dispatchModalState] = useReducer(
        helper.modalStateReducer,
        {
            actionID: null,
            actionDescription: '',
            goalIDs: [],
            strategyIDs: [],
            type: null,
        }
    );
    const municipalityID = useRecoilValue(atomMunicipalityID);
    const location = useLocation();
    const navigate = useNavigate();
    const [urlSearchQuery, setURLSearchQuery] = useState<string>('');

    // Callbacks
    const loadTableData = useCallback(async () => {
        if (municipalityID) {
            const { rows, count } = await helper.tableLoad(
                Number(municipalityID),
                tableState
            );
            dispatchTableData({
                type: 'setState',
                payload: { rows, count },
            });
        }
    }, [municipalityID, tableState, dispatchTableData]);

    const handleOpenAddModal = useCallback(
        (_: React.MouseEvent<HTMLElement>) => {
            dispatchModalState({ type: 'openAddModal' });
        },
        [dispatchModalState]
    );

    const handleOpenEditModal = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            const actionID = Number(event.currentTarget.id);
            const actionDescription =
                event.currentTarget.getAttribute('data-action') || '';
            const row = tableData.rows?.find(row => row.id === actionID);
            const goalIDs = row?.goalIDs || [];
            const strategyIDs = row?.strategyIDs || [];
            dispatchModalState({
                type: 'openEditModal',
                payload: { actionID, actionDescription, goalIDs, strategyIDs },
            });
        },
        [dispatchModalState, tableData]
    );

    const handleOpenDeleteModal = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            const actionID = Number(event.currentTarget.id);
            const actionDescription =
                event.currentTarget.getAttribute('data-action') || '';
            dispatchModalState({
                type: 'openDeleteModal',
                payload: { actionID, actionDescription },
            });
        },
        [dispatchModalState]
    );

    const handleCloseModal = useCallback(() => {
        if (tableState.pageNumber !== 0) {
            const newTableState = {
                ...tableState,
                pageNumber: 0,
            };
            dispatchTableState({
                type: 'setState',
                payload: newTableState,
            });
        } else {
            loadTableData();
        }
        dispatchModalState({ type: 'closeModal' });
    }, [tableState, dispatchTableState, loadTableData, dispatchModalState]);

    const handleChangePage = useCallback(
        async (_: React.ChangeEvent<unknown>, value: number) => {
            dispatchTableState({
                type: 'setState',
                payload: {
                    ...tableState,
                    pageNumber: value - 1,
                },
            });
        },
        [dispatchTableState, tableState]
    );

    const debouncedSearch = useMemo(
        () =>
            debounce((searchQuery: string) => {
                dispatchTableState({
                    type: 'setState',
                    payload: {
                        ...tableState,
                        searchQuery,
                        pageNumber: 0,
                    },
                });
            }, 500),
        [dispatchTableState, tableState]
    );

    const handleChangeSearchQuery = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            location.hash = '';
            navigate(location);
            setURLSearchQuery('');
            debouncedSearch(event.target.value);
        },
        [debouncedSearch, location, navigate]
    );

    const handleHash = useCallback(() => {
        const hash = location.hash;
        if (hash) {
            const hashArray = hash.split('=');
            if (hashArray.length === 2) {
                if (hashArray[0] === '#goalID') {
                    setURLSearchQuery(`goalID=${hashArray[1]}`);
                    dispatchTableState({
                        type: 'setState',
                        payload: {
                            ...tableState,
                            searchQuery: `goalID=${hashArray[1]}`,
                            pageNumber: 0,
                        },
                    });
                } else if (hashArray[0] === '#strategyID') {
                    setURLSearchQuery(`strategyID=${hashArray[1]}`);
                    dispatchTableState({
                        type: 'setState',
                        payload: {
                            ...tableState,
                            searchQuery: `strategyID=${hashArray[1]}`,
                            pageNumber: 0,
                        },
                    });
                }
            }
        }
    }, [location, setURLSearchQuery, dispatchTableState, tableState]);

    // UseEffects
    useEffect(() => {
        // Load table data on every tableState change or location change
        loadTableData();
    }, [loadTableData]);

    useEffect(() => {
        handleHash();
    }, [handleHash]);

    // Return
    return {
        t,
        tableState,
        tableData,
        modalState,
        handleOpenAddModal,
        handleOpenEditModal,
        handleOpenDeleteModal,
        handleCloseModal,
        handleChangePage,
        handleChangeSearchQuery,
        urlSearchQuery,
    };
}

const Hook = {
    useActionsTab,
};

export default Hook;
