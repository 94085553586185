import { Box, Button, Container, Grid, Modal, Typography } from '@mui/material';
import { IUseDeleteActionModalProps } from './useDeleteActionModal';
import Hook from './useDeleteActionModal';

interface IDeleteActionModalProps extends IUseDeleteActionModalProps {
    actionDescription: string;
}

function DeleteActionModal(props: IDeleteActionModalProps) {
    const { handleCloseModal, actionID, actionDescription } = props;
    const useDeleteActionModalProps = {
        handleCloseModal,
        actionID,
    };
    const { handleClickConfirm } = Hook.useDeleteActionModal(
        useDeleteActionModalProps
    );

    return (
        <Modal open={true} onClose={handleCloseModal}>
            <Container maxWidth="sm">
                <Box bgcolor={'background.paper'}>
                    <Grid container padding={4}>
                        <Grid item xs={3}></Grid>
                        <Grid
                            item
                            xs={6}
                            display={'flex'}
                            justifyContent={'center'}
                        >
                            <Typography variant="h5">
                                {'Delete Action'}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            display={'flex'}
                            justifyContent={'flex-end'}
                        >
                            <Button onClick={handleCloseModal}> X </Button>
                        </Grid>
                        <Typography variant="h6">
                            {`Are you sure you want to delete ${actionDescription}?`}
                        </Typography>
                        <Button onClick={handleClickConfirm}> Confirm </Button>
                    </Grid>
                </Box>
            </Container>
        </Modal>
    );
}

export default DeleteActionModal;
