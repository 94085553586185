import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import helper from './municipalityDashboardPageHelper';
import { useSetRecoilState } from 'recoil';
import { atomMunicipalityID } from '../../state/atoms';

export interface IUseMunicipalityDashboardPageProps {}

function useMunicipalityDashboardPage(
    props: IUseMunicipalityDashboardPageProps
) {
    const { t } = useTranslation();
    const [tabState, setTabState] = useState<number | null>(null);
    const location = useLocation();
    const navigate = useNavigate();
    const setMunicipalityID = useSetRecoilState(atomMunicipalityID);

    // Callbacks
    const handleChangeTab = useCallback(
        (_: React.ChangeEvent<{}>, newValue: number) => {
            helper.addTabQuery(location, navigate, newValue);
        },
        [location, navigate]
    );

    const handleQueryStrings = useCallback(() => {
        const tab = new URLSearchParams(location.search).get('tab');
        const municipalityID = new URLSearchParams(location.search).get('id');
        if (tab) {
            setTabState(parseInt(tab));
        } else {
            helper.addTabQuery(location, navigate, 0, true);
        }
        if (municipalityID) {
            setMunicipalityID(parseInt(municipalityID));
        }
    }, [location, navigate, setMunicipalityID]);

    // UseEffects
    useEffect(() => {
        // Set the tab state based on the URL query
        handleQueryStrings();
    }, [handleQueryStrings]);

    // Return
    return {
        t,
        tabState,
        handleChangeTab,
    };
}

const Hook = {
    useMunicipalityDashboardPage,
};

export default Hook;
