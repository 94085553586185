import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import helper from './goalsTabHelper';
import generalUtil from '../../../utils/generalUtil/generalUtil';
import { TTableArgs } from '../../../typescript/ITCommon';
import { databaseModels_MunicipalityGoal } from '../../../api-autogenerated';
import { useRecoilValue } from 'recoil';
import { atomMunicipalityID } from '../../../state/atoms';
import { debounce } from 'lodash';

export interface IUseGoalsTabProps {}

function useGoalsTab(props: IUseGoalsTabProps) {
    const { t } = useTranslation();
    const [tableState, dispatchTableState] = useReducer(
        generalUtil.tableStateReducer,
        {
            pageNumber: 0,
            numRows: 5,
            searchQuery: undefined,
            sortHeader: 'id',
            sortDirection: 'asc',
        } as TTableArgs
    );
    const [tableData, dispatchTableData] = useReducer(helper.tableDataReducer, {
        rows: [] as databaseModels_MunicipalityGoal[],
        count: 0,
    });
    const [modalState, dispatchModalState] = useReducer(
        helper.modalStateReducer,
        {
            goalID: null,
            goalDescription: '',
            goalColor: '',
            type: null,
        }
    );
    const municipalityID = useRecoilValue(atomMunicipalityID);

    // Callbacks
    const loadTableData = useCallback(async () => {
        if (municipalityID) {
            const { rows, count } = await helper.tableLoad(
                Number(municipalityID),
                tableState
            );
            dispatchTableData({
                type: 'setState',
                payload: { rows, count },
            });
        }
    }, [municipalityID, tableState, dispatchTableData]);

    const handleOpenAddModal = useCallback(
        (_: React.MouseEvent<HTMLElement>) => {
            dispatchModalState({ type: 'openAddModal' });
        },
        [dispatchModalState]
    );

    const handleOpenEditModal = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            const goalID = Number(event.currentTarget.id);
            const goalDescription =
                event.currentTarget.getAttribute('data-goal') || '';
            const goalColor =
                event.currentTarget.getAttribute('data-color') || '';
            dispatchModalState({
                type: 'openEditModal',
                payload: { goalID, goalDescription, goalColor },
            });
        },
        [dispatchModalState]
    );

    const handleOpenDeleteModal = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            const goalID = Number(event.currentTarget.id);
            const goalDescription =
                event.currentTarget.getAttribute('data-goal') || '';
            dispatchModalState({
                type: 'openDeleteModal',
                payload: { goalID, goalDescription },
            });
        },
        [dispatchModalState]
    );

    const handleCloseModal = useCallback(
        (_: React.MouseEvent<HTMLElement>) => {
            if (tableState.pageNumber !== 0) {
                const newTableState = {
                    ...tableState,
                    pageNumber: 0,
                };
                dispatchTableState({
                    type: 'setState',
                    payload: newTableState,
                });
            } else {
                loadTableData();
            }
            dispatchModalState({ type: 'closeModal' });
        },
        [tableState, dispatchTableState, loadTableData, dispatchModalState]
    );

    const handleChangeTableState = useCallback(
        async (newTableState: TTableArgs) => {
            dispatchTableState({
                type: 'setState',
                payload: newTableState,
            });
        },
        [dispatchTableState]
    );

    const debouncedSearch = useMemo(
        () =>
            debounce((searchQuery: string) => {
                dispatchTableState({
                    type: 'setState',
                    payload: {
                        ...tableState,
                        searchQuery,
                        pageNumber: 0,
                    },
                });
            }, 500),
        [dispatchTableState, tableState]
    );

    const handleChangeSearchQuery = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            debouncedSearch(event.target.value);
        },
        [debouncedSearch]
    );

    // UseEffects
    useEffect(() => {
        // Load table data on every tableState change or location change
        loadTableData();
    }, [loadTableData]);

    // Return
    return {
        t,
        tableState,
        tableData,
        modalState,
        handleOpenAddModal,
        handleOpenEditModal,
        handleOpenDeleteModal,
        handleCloseModal,
        handleChangeTableState,
        handleChangeSearchQuery,
    };
}

const Hook = {
    useGoalsTab,
};

export default Hook;
