import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import helper from './addEditStrategyModalHelper';
import { useRecoilValue } from 'recoil';
import { atomMunicipalityID } from '../../../../state/atoms';
import { SelectChangeEvent } from '@mui/material';

export interface IUseAddEditStrategyModalProps {
    handleCloseModal: (_: React.MouseEvent<HTMLElement>) => void;
    strategyID: number | null;
    strategyDescription: string;
    strategyColor: string;
}

function useAddEditStrategyModal(props: IUseAddEditStrategyModalProps) {
    const { handleCloseModal, strategyID, strategyDescription, strategyColor } =
        props;
    const { t } = useTranslation();
    const [addEditModalState, dispatchAddEditModalState] = useReducer(
        helper.addEditModalStateReducer,
        {
            title: null,
            strategyID: null,
            strategyDescription: '',
            strategyColor: '',
        }
    );
    const municipalityID = useRecoilValue(atomMunicipalityID);

    // Callbacks
    const handleChangeStrategyDescription = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            dispatchAddEditModalState({
                type: 'setStrategyDescription',
                payload: { strategyDescription: event.target.value },
            });
        },
        [dispatchAddEditModalState]
    );

    const handleClickConfirm = useCallback(
        async (event: React.MouseEvent<HTMLElement>) => {
            const result = await helper.handleSave(
                strategyID,
                addEditModalState.strategyDescription,
                addEditModalState.strategyColor,
                municipalityID
            );
            if (result) {
                handleCloseModal(event);
            }
        },
        [
            strategyID,
            addEditModalState.strategyDescription,
            addEditModalState.strategyColor,
            municipalityID,
            handleCloseModal,
        ]
    );

    const handleChangeStrategyColor = useCallback(
        (event: SelectChangeEvent<string>) => {
            dispatchAddEditModalState({
                type: 'setStrategyColor',
                payload: { strategyColor: event.target.value },
            });
        },
        [dispatchAddEditModalState]
    );

    // UseEffects
    useEffect(() => {
        dispatchAddEditModalState({
            type: 'setModalType',
            payload: { strategyID, strategyDescription, strategyColor },
        });
    }, [
        dispatchAddEditModalState,
        strategyID,
        strategyDescription,
        strategyColor,
    ]);

    // Return
    return {
        t,
        addEditModalState,
        handleChangeStrategyDescription,
        handleClickConfirm,
        handleChangeStrategyColor,
    };
}

const Hook = {
    useAddEditStrategyModal,
};

export default Hook;
