import {
    Box,
    Button,
    Container,
    Grid,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import { IUseAddEditGoalModalProps } from './useAddEditGoalModal';
import Hook from './useAddEditGoalModal';
import ColorSelect from '../../../../components/ColorSelect/ColorSelect';

interface IAddEditGoalModalProps extends IUseAddEditGoalModalProps {}

function AddEditGoalModal(props: IAddEditGoalModalProps) {
    const { handleCloseModal, goalID, goalDescription, goalColor } = props;
    const useAddEditGoalModalProps = {
        handleCloseModal,
        goalID,
        goalDescription,
        goalColor,
    };
    const {
        addEditModalState,
        handleChangeGoalDescription,
        handleClickConfirm,
        handleChangeGoalColor,
    } = Hook.useAddEditGoalModal(useAddEditGoalModalProps);

    return (
        <Modal open={true} onClose={handleCloseModal}>
            <Container maxWidth="sm">
                <Box bgcolor={'background.paper'}>
                    <Grid container padding={4}>
                        <Grid item xs={3}></Grid>
                        <Grid
                            item
                            xs={6}
                            display={'flex'}
                            justifyContent={'center'}
                        >
                            <Typography variant="h5">
                                {addEditModalState.title}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            display={'flex'}
                            justifyContent={'flex-end'}
                        >
                            <Button onClick={handleCloseModal}> X </Button>
                        </Grid>
                        <TextField
                            fullWidth
                            label={'Goal Description'}
                            value={addEditModalState.goalDescription}
                            onChange={handleChangeGoalDescription}
                        />
                        <ColorSelect
                            color={addEditModalState.goalColor}
                            onChange={handleChangeGoalColor}
                        />
                        <Button onClick={handleClickConfirm}> Confirm </Button>
                    </Grid>
                </Box>
            </Container>
        </Modal>
    );
}

export default AddEditGoalModal;
