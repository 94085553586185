// state.ts
import { atom } from 'recoil';

export const atomMunicipalityID = atom({
    key: 'atomMunicipalityID',
    default: null as number | null,
});

export const atomToken = atom({
    key: 'atomToken',
    default: null as string | null,
});
