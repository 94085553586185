import {
    Button,
    Grid,
    Link,
    Pagination,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import PencilIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import Hook, { IUseActionsTabProps } from './useActionsTab';
import AddEditActionModal from './AddEditActionsModal/AddEditActionModal';
import DeleteActionModal from './DeleteActionModal/DeleteActionModal';

export interface IActionsTabProps extends IUseActionsTabProps {}

function ActionsTab(props: IActionsTabProps) {
    const useActionsTabProps = {};
    const {
        tableData,
        tableState,
        modalState,
        handleOpenAddModal,
        handleOpenEditModal,
        handleOpenDeleteModal,
        handleCloseModal,
        handleChangePage,
        handleChangeSearchQuery,
        urlSearchQuery,
    } = Hook.useActionsTab(useActionsTabProps);

    const renderModal = () => {
        if (modalState.type === 'Add_Edit') {
            return (
                <AddEditActionModal
                    handleCloseModal={handleCloseModal}
                    actionID={modalState.actionID}
                    actionDescription={modalState.actionDescription}
                    goalIDs={modalState.goalIDs}
                    strategyIDs={modalState.strategyIDs}
                />
            );
        } else if (modalState.type === 'Delete') {
            return (
                <DeleteActionModal
                    handleCloseModal={handleCloseModal}
                    actionID={modalState.actionID}
                    actionDescription={modalState.actionDescription}
                />
            );
        }
        return <></>;
    };

    const renderActionBox = (
        actionID: number,
        actionDescription: string,
        goals: {
            goal?: string;
            color?: string;
        }[],
        goalIDs: number[],
        strategies: {
            strategy?: string;
            color?: string;
        }[],
        strategyIDs: number[]
    ) => {
        const newURL = new URL(window.location.href);

        let displayActionDescription: string = actionDescription;
        let showTooltip: boolean = false;
        if (displayActionDescription.length > 60) {
            displayActionDescription =
                actionDescription.substring(0, 60) + '...';
            showTooltip = true;
        }

        const getDisplayGoal = (
            goal: {
                goal?: string;
                color?: string;
            },
            goalID: number
        ) => {
            if (goal.goal === '' || goal.color === '') {
                return <></>;
            }
            let displayGoal: string = `@${goal.goal}`;
            if (displayGoal.length > 25) {
                displayGoal = displayGoal.substring(0, 25) + '...';
            }
            const newFragment = `goalID=${goalID}`;
            newURL.hash = newFragment;
            return (
                <Link href={newURL.href} pr={2} color={goal.color}>
                    {displayGoal}
                </Link>
            );
        };

        const getDisplayStrategy = (
            strategy: {
                strategy?: string;
                color?: string;
            },
            strategyID: number
        ) => {
            if (strategy.strategy === '' || strategy.color === '') {
                return <></>;
            }
            let displayStrategy: string = `#${strategy.strategy}`;
            if (displayStrategy.length > 25) {
                displayStrategy = displayStrategy.substring(0, 25) + '...';
            }
            const newHash = `strategyID=${strategyID}`;
            newURL.hash = newHash;
            return (
                <Link href={newURL.href} pr={2} color={strategy.color}>
                    {displayStrategy}
                </Link>
            );
        };

        const renderGoals = () => {
            const goalLinks = [];
            for (let i = 0; i < goals.length; i++) {
                const goal = goals[i];
                const goalID = goalIDs[i];
                goalLinks.push(getDisplayGoal(goal, goalID));
            }
            return goalLinks;
        };

        const renderStrategies = () => {
            const strategyLinks = [];
            for (let i = 0; i < strategies.length; i++) {
                const strategy = strategies[i];
                const strategyID = strategyIDs[i];
                strategyLinks.push(getDisplayStrategy(strategy, strategyID));
            }
            return strategyLinks;
        };

        const goalPadding = goals.length > 1 ? 1 : 0;
        const strategyPadding = strategies.length > 1 ? 1 : 0;

        return (
            <Grid container border={1} borderRadius={5} padding={2} my={4}>
                <Grid item xs={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Tooltip
                                title={showTooltip ? actionDescription : ''}
                                placement="top-start"
                            >
                                <Typography variant="h6">
                                    {displayActionDescription}
                                </Typography>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} pt={goalPadding}>
                            {renderGoals()}
                        </Grid>
                        <Grid item xs={12} pt={strategyPadding}>
                            {renderStrategies()}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3} display="flex" justifyContent="flex-end">
                    <Button
                        id={String(actionID)}
                        data-action={actionDescription}
                        onClick={handleOpenEditModal}
                    >
                        <PencilIcon />
                    </Button>
                    <Button
                        id={String(actionID)}
                        data-action={actionDescription}
                        onClick={handleOpenDeleteModal}
                    >
                        <DeleteIcon />
                    </Button>
                </Grid>
            </Grid>
        );
    };

    const renderActionsList = () => {
        const actionBoxes = [];
        if (tableData.rows !== undefined) {
            for (let i = 0; i < tableData.rows.length; i++) {
                const row = tableData.rows[i];
                actionBoxes.push(
                    renderActionBox(
                        row.id ?? 0,
                        row.action ?? '',
                        row.goals ?? [],
                        row.goalIDs ?? [],
                        row.strategies ?? [],
                        row.strategyIDs ?? []
                    )
                );
            }
            return actionBoxes;
        }
        return <></>;
    };

    return (
        <Grid container>
            <Grid item xs={6} display="flex" justifyContent="flex-start">
                <Button onClick={handleOpenAddModal}> + Add Action </Button>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-end">
                <TextField
                    fullWidth
                    label={'Search'}
                    value={urlSearchQuery || undefined}
                    InputLabelProps={urlSearchQuery ? { shrink: true } : {}}
                    onChange={handleChangeSearchQuery}
                />
            </Grid>
            <Grid item xs={12}>
                {renderActionsList()}
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
                <Pagination
                    count={
                        tableState.numRows
                            ? Math.ceil(tableData.count / tableState.numRows)
                            : 1
                    }
                    page={tableState.pageNumber ? tableState.pageNumber + 1 : 1}
                    onChange={handleChangePage}
                />
            </Grid>
            {renderModal()}
        </Grid>
    );
}

export default ActionsTab;
