import {
    Box,
    Button,
    Container,
    Grid,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import { IUseAddEditStrategyModalProps } from './useAddEditStrategyModal';
import Hook from './useAddEditStrategyModal';
import ColorSelect from '../../../../components/ColorSelect/ColorSelect';

interface IAddEditStrategyModalProps extends IUseAddEditStrategyModalProps {}

function AddEditStrategyModal(props: IAddEditStrategyModalProps) {
    const { handleCloseModal, strategyID, strategyDescription, strategyColor } =
        props;
    const useAddEditStrategyModalProps = {
        handleCloseModal,
        strategyID,
        strategyDescription,
        strategyColor,
    };
    const {
        addEditModalState,
        handleChangeStrategyDescription,
        handleClickConfirm,
        handleChangeStrategyColor,
    } = Hook.useAddEditStrategyModal(useAddEditStrategyModalProps);

    return (
        <Modal open={true} onClose={handleCloseModal}>
            <Container maxWidth="sm">
                <Box bgcolor={'background.paper'}>
                    <Grid container padding={4}>
                        <Grid item xs={3}></Grid>
                        <Grid
                            item
                            xs={6}
                            display={'flex'}
                            justifyContent={'center'}
                        >
                            <Typography variant="h5">
                                {addEditModalState.title}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            display={'flex'}
                            justifyContent={'flex-end'}
                        >
                            <Button onClick={handleCloseModal}> X </Button>
                        </Grid>
                        <TextField
                            fullWidth
                            label={'Strategy Description'}
                            value={addEditModalState.strategyDescription}
                            onChange={handleChangeStrategyDescription}
                        />
                        <ColorSelect
                            color={addEditModalState.strategyColor}
                            onChange={handleChangeStrategyColor}
                        />
                        <Button onClick={handleClickConfirm}> Confirm </Button>
                    </Grid>
                </Box>
            </Container>
        </Modal>
    );
}

export default AddEditStrategyModal;
