import _ from 'lodash';
import { DefaultService } from '../../../../api-autogenerated';

const addEditModalStateReducer = (
    state: {
        title: 'Add Goal' | 'Edit Goal' | null;
        goalID: number | null;
        goalDescription: string;
        goalColor: string;
    },
    action:
        | {
              type: 'setModalType';
              payload: {
                  goalID: number | null;
                  goalDescription: string;
                  goalColor: string;
              };
          }
        | { type: 'setGoalDescription'; payload: { goalDescription: string } }
        | { type: 'setGoalColor'; payload: { goalColor: string } }
): {
    title: 'Add Goal' | 'Edit Goal' | null;
    goalID: number | null;
    goalDescription: string;
    goalColor: string;
} => {
    let newState;
    switch (action.type) {
        case 'setModalType':
            newState = {
                title: action.payload.goalID
                    ? ('Edit Goal' as const)
                    : ('Add Goal' as const),
                goalID: action.payload.goalID,
                goalDescription: action.payload.goalDescription,
                goalColor: action.payload.goalColor,
            };
            return _.isEqual(newState, state) ? state : newState;
        case 'setGoalDescription':
            newState = {
                ...state,
                goalDescription: action.payload.goalDescription,
            };
            return _.isEqual(newState, state) ? state : newState;
        case 'setGoalColor':
            newState = {
                ...state,
                goalColor: action.payload.goalColor,
            };
            return _.isEqual(newState, state) ? state : newState;
        default:
            return state;
    }
};

const handleSave = async (
    goalID: number | null,
    goalDescription: string,
    goalColor: string,
    municipalityID: number | null
): Promise<boolean> => {
    if (!municipalityID) {
        return new Promise<boolean>(resolve => resolve(false));
    }
    return new Promise<boolean>(async (resolve, reject) => {
        if (goalID) {
            try {
                await DefaultService.postMunicipalityDashboardPageUpdateGoal({
                    municipalityID,
                    goalID,
                    goal: goalDescription,
                    color: goalColor,
                });
                resolve(true);
            } catch (error) {
                console.error(error);
                resolve(false);
            }
        } else {
            try {
                await DefaultService.postMunicipalityDashboardPageAddGoal({
                    municipalityID,
                    goal: goalDescription,
                    color: goalColor,
                });
                resolve(true);
            } catch (error) {
                console.error(error);
                resolve(false);
            }
        }
    });
};

const helper = {
    addEditModalStateReducer,
    handleSave,
};

export default helper;
