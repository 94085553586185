import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import helper from './deleteActionModalHelper';
import { useRecoilValue } from 'recoil';
import { atomMunicipalityID } from '../../../../state/atoms';

export interface IUseDeleteActionModalProps {
    handleCloseModal: () => void;
    actionID: number | null;
}

function useDeleteActionModal(props: IUseDeleteActionModalProps) {
    const { handleCloseModal, actionID } = props;
    const { t } = useTranslation();
    const municipalityID = useRecoilValue(atomMunicipalityID);

    // Props

    // State

    // Callbacks

    const handleClickConfirm = useCallback(async () => {
        const result = await helper.confirmDelete(actionID, municipalityID);
        if (result) {
            handleCloseModal();
        }
    }, [actionID, municipalityID, handleCloseModal]);

    // UseEffects

    // Return
    return {
        t,
        handleClickConfirm,
    };
}

const Hook = {
    useDeleteActionModal,
};

export default Hook;
