import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import helper from './deleteMunicipalityModalHelper';
import { useRecoilValue } from 'recoil';
import { atomToken } from '../../../../state/atoms';

export interface IUseDeleteMunicipalityModalProps {
    handleCloseModal: (_: React.MouseEvent<HTMLElement>) => void;
    municipalityId: number | null;
}

function useDeleteMunicipalityModal(props: IUseDeleteMunicipalityModalProps) {
    const { handleCloseModal, municipalityId } = props;
    const { t } = useTranslation();
    const token = useRecoilValue(atomToken);

    // Props

    // State

    // Callbacks

    const handleClickConfirm = useCallback(
        async (event: React.MouseEvent<HTMLElement>) => {
            const result = await helper.confirmDelete(token, municipalityId);
            if (result) {
                handleCloseModal(event);
            }
        },
        [token, municipalityId, handleCloseModal]
    );

    // UseEffects

    // Return
    return {
        t,
        handleClickConfirm,
    };
}

const Hook = {
    useDeleteMunicipalityModal,
};

export default Hook;
