/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { endpoints_AdminMunicipalitiesPage_AddMunicipalityCSVResponse } from '../models/endpoints_AdminMunicipalitiesPage_AddMunicipalityCSVResponse';
import type { endpoints_AdminMunicipalitiesPage_AddMunicipalityRequest } from '../models/endpoints_AdminMunicipalitiesPage_AddMunicipalityRequest';
import type { endpoints_AdminMunicipalitiesPage_AddMunicipalityResponse } from '../models/endpoints_AdminMunicipalitiesPage_AddMunicipalityResponse';
import type { endpoints_AdminMunicipalitiesPage_DeleteMunicipalityRequest } from '../models/endpoints_AdminMunicipalitiesPage_DeleteMunicipalityRequest';
import type { endpoints_AdminMunicipalitiesPage_DeleteMunicipalityResponse } from '../models/endpoints_AdminMunicipalitiesPage_DeleteMunicipalityResponse';
import type { endpoints_AdminMunicipalitiesPage_MunicipalitiesTableResponse } from '../models/endpoints_AdminMunicipalitiesPage_MunicipalitiesTableResponse';
import type { endpoints_AdminMunicipalitiesPage_UpdateMunicipalityRequest } from '../models/endpoints_AdminMunicipalitiesPage_UpdateMunicipalityRequest';
import type { endpoints_AdminMunicipalitiesPage_UpdateMunicipalityResponse } from '../models/endpoints_AdminMunicipalitiesPage_UpdateMunicipalityResponse';
import type { endpoints_Common_HealthResponse } from '../models/endpoints_Common_HealthResponse';
import type { endpoints_MunicipalityDashboardPage_ActionsTableResponse } from '../models/endpoints_MunicipalityDashboardPage_ActionsTableResponse';
import type { endpoints_MunicipalityDashboardPage_AddActionRequest } from '../models/endpoints_MunicipalityDashboardPage_AddActionRequest';
import type { endpoints_MunicipalityDashboardPage_AddActionResponse } from '../models/endpoints_MunicipalityDashboardPage_AddActionResponse';
import type { endpoints_MunicipalityDashboardPage_AddGoalRequest } from '../models/endpoints_MunicipalityDashboardPage_AddGoalRequest';
import type { endpoints_MunicipalityDashboardPage_AddGoalResponse } from '../models/endpoints_MunicipalityDashboardPage_AddGoalResponse';
import type { endpoints_MunicipalityDashboardPage_AddStrategyRequest } from '../models/endpoints_MunicipalityDashboardPage_AddStrategyRequest';
import type { endpoints_MunicipalityDashboardPage_AddStrategyResponse } from '../models/endpoints_MunicipalityDashboardPage_AddStrategyResponse';
import type { endpoints_MunicipalityDashboardPage_DeleteActionRequest } from '../models/endpoints_MunicipalityDashboardPage_DeleteActionRequest';
import type { endpoints_MunicipalityDashboardPage_DeleteActionResponse } from '../models/endpoints_MunicipalityDashboardPage_DeleteActionResponse';
import type { endpoints_MunicipalityDashboardPage_DeleteGoalRequest } from '../models/endpoints_MunicipalityDashboardPage_DeleteGoalRequest';
import type { endpoints_MunicipalityDashboardPage_DeleteGoalResponse } from '../models/endpoints_MunicipalityDashboardPage_DeleteGoalResponse';
import type { endpoints_MunicipalityDashboardPage_DeleteStrategyRequest } from '../models/endpoints_MunicipalityDashboardPage_DeleteStrategyRequest';
import type { endpoints_MunicipalityDashboardPage_DeleteStrategyResponse } from '../models/endpoints_MunicipalityDashboardPage_DeleteStrategyResponse';
import type { endpoints_MunicipalityDashboardPage_GoalsTableResponse } from '../models/endpoints_MunicipalityDashboardPage_GoalsTableResponse';
import type { endpoints_MunicipalityDashboardPage_StrategiesTableResponse } from '../models/endpoints_MunicipalityDashboardPage_StrategiesTableResponse';
import type { endpoints_MunicipalityDashboardPage_UpdateActionRequest } from '../models/endpoints_MunicipalityDashboardPage_UpdateActionRequest';
import type { endpoints_MunicipalityDashboardPage_UpdateActionResponse } from '../models/endpoints_MunicipalityDashboardPage_UpdateActionResponse';
import type { endpoints_MunicipalityDashboardPage_UpdateGoalRequest } from '../models/endpoints_MunicipalityDashboardPage_UpdateGoalRequest';
import type { endpoints_MunicipalityDashboardPage_UpdateGoalResponse } from '../models/endpoints_MunicipalityDashboardPage_UpdateGoalResponse';
import type { endpoints_MunicipalityDashboardPage_UpdateStrategyRequest } from '../models/endpoints_MunicipalityDashboardPage_UpdateStrategyRequest';
import type { endpoints_MunicipalityDashboardPage_UpdateStrategyResponse } from '../models/endpoints_MunicipalityDashboardPage_UpdateStrategyResponse';
import type { structs_ErrorResponse } from '../models/structs_ErrorResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {
    /**
     * Add Municipality Customer
     * This will add a new municipality customer
     * @param addMunicipalityRequest Add Municipality Request Parameters
     * @param authorization Authorization header containing the bearer token
     * @returns endpoints_AdminMunicipalitiesPage_AddMunicipalityResponse OK
     * @returns structs_ErrorResponse
     * @throws ApiError
     */
    public static postAdminMunicipalitiesPageAddMunicipality(
        addMunicipalityRequest: endpoints_AdminMunicipalitiesPage_AddMunicipalityRequest,
        authorization: string
    ): CancelablePromise<
        | endpoints_AdminMunicipalitiesPage_AddMunicipalityResponse
        | structs_ErrorResponse
    > {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/adminMunicipalitiesPage/addMunicipality',
            headers: {
                Authorization: authorization,
            },
            body: addMunicipalityRequest,
        });
    }

    /**
     * Add Municipality Customer CSV
     * This will fill in the municipality details for a new municipality customer using a CSV file
     * @param file CSV file containing the municipality details
     * @param name Name of the municipality
     * @param authorization Authorization header containing the bearer token
     * @param provinceState Province/State of the municipality
     * @returns endpoints_AdminMunicipalitiesPage_AddMunicipalityCSVResponse OK
     * @returns structs_ErrorResponse
     * @throws ApiError
     */
    public static postAdminMunicipalitiesPageAddMunicipalityCsv(
        file: Blob,
        name: string,
        authorization: string,
        provinceState?: string
    ): CancelablePromise<
        | endpoints_AdminMunicipalitiesPage_AddMunicipalityCSVResponse
        | structs_ErrorResponse
    > {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/adminMunicipalitiesPage/addMunicipalityCSV',
            headers: {
                Authorization: authorization,
            },
            formData: {
                file: file,
                name: name,
                province_state: provinceState,
            },
        });
    }

    /**
     * Delete Municipality Customer
     * This will delete a municipality customer
     * @param deleteMunicipalityRequest Delete Municipality Request Parameters
     * @param authorization Authorization header containing the bearer token
     * @returns endpoints_AdminMunicipalitiesPage_DeleteMunicipalityResponse OK
     * @returns structs_ErrorResponse
     * @throws ApiError
     */
    public static deleteAdminMunicipalitiesPageDeleteMunicipality(
        deleteMunicipalityRequest: endpoints_AdminMunicipalitiesPage_DeleteMunicipalityRequest,
        authorization: string
    ): CancelablePromise<
        | endpoints_AdminMunicipalitiesPage_DeleteMunicipalityResponse
        | structs_ErrorResponse
    > {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/adminMunicipalitiesPage/deleteMunicipality',
            headers: {
                Authorization: authorization,
            },
            body: deleteMunicipalityRequest,
        });
    }

    /**
     * Get Municipalities Table Data
     * This will return the data for the municipalities table
     * @param authorization Authorization header containing the bearer token
     * @param numRows
     * @param pageNumber
     * @param searchQuery
     * @param sortDirection
     * @param sortHeader
     * @returns endpoints_AdminMunicipalitiesPage_MunicipalitiesTableResponse OK
     * @returns structs_ErrorResponse
     * @throws ApiError
     */
    public static getAdminMunicipalitiesPageMunicipalitiesTable(
        authorization: string,
        numRows?: number,
        pageNumber?: number,
        searchQuery?: string,
        sortDirection?: 'asc' | 'desc',
        sortHeader?: string
    ): CancelablePromise<
        | endpoints_AdminMunicipalitiesPage_MunicipalitiesTableResponse
        | structs_ErrorResponse
    > {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/adminMunicipalitiesPage/municipalitiesTable',
            headers: {
                Authorization: authorization,
            },
            query: {
                numRows: numRows,
                pageNumber: pageNumber,
                searchQuery: searchQuery,
                sortDirection: sortDirection,
                sortHeader: sortHeader,
            },
        });
    }

    /**
     * Update Municipality Customer
     * This will update a municipality customer
     * @param updateMunicipalityRequest Update Municipality Request Parameters
     * @param authorization Authorization header containing the bearer token
     * @returns endpoints_AdminMunicipalitiesPage_UpdateMunicipalityResponse OK
     * @returns structs_ErrorResponse
     * @throws ApiError
     */
    public static postAdminMunicipalitiesPageUpdateMunicipality(
        updateMunicipalityRequest: endpoints_AdminMunicipalitiesPage_UpdateMunicipalityRequest,
        authorization: string
    ): CancelablePromise<
        | endpoints_AdminMunicipalitiesPage_UpdateMunicipalityResponse
        | structs_ErrorResponse
    > {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/adminMunicipalitiesPage/updateMunicipality',
            headers: {
                Authorization: authorization,
            },
            body: updateMunicipalityRequest,
        });
    }

    /**
     * Get health status of the server
     * This will return just a 200 status code if the server is up and running
     * @returns endpoints_Common_HealthResponse OK
     * @returns structs_ErrorResponse
     * @throws ApiError
     */
    public static getCommonHealth(): CancelablePromise<
        endpoints_Common_HealthResponse | structs_ErrorResponse
    > {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/common/health',
        });
    }

    /**
     * Get Actions Table Data
     * This will return the data for the actions table
     * @param municipalityId
     * @param numRows
     * @param pageNumber
     * @param searchQuery
     * @param sortDirection
     * @param sortHeader
     * @returns endpoints_MunicipalityDashboardPage_ActionsTableResponse OK
     * @returns structs_ErrorResponse
     * @throws ApiError
     */
    public static getMunicipalityDashboardPageActionsTable(
        municipalityId: number,
        numRows?: number,
        pageNumber?: number,
        searchQuery?: string,
        sortDirection?: 'asc' | 'desc',
        sortHeader?: string
    ): CancelablePromise<
        | endpoints_MunicipalityDashboardPage_ActionsTableResponse
        | structs_ErrorResponse
    > {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/municipalityDashboardPage/actionsTable',
            query: {
                municipalityID: municipalityId,
                numRows: numRows,
                pageNumber: pageNumber,
                searchQuery: searchQuery,
                sortDirection: sortDirection,
                sortHeader: sortHeader,
            },
        });
    }

    /**
     * Add Action
     * This will add a new action
     * @param addActionRequest Add Action Request Parameters
     * @returns endpoints_MunicipalityDashboardPage_AddActionResponse OK
     * @returns structs_ErrorResponse
     * @throws ApiError
     */
    public static postMunicipalityDashboardPageAddAction(
        addActionRequest: endpoints_MunicipalityDashboardPage_AddActionRequest
    ): CancelablePromise<
        | endpoints_MunicipalityDashboardPage_AddActionResponse
        | structs_ErrorResponse
    > {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/municipalityDashboardPage/addAction',
            body: addActionRequest,
        });
    }

    /**
     * Add Goal
     * This will add a new goal
     * @param addGoalRequest Add Goal Request Parameters
     * @returns endpoints_MunicipalityDashboardPage_AddGoalResponse OK
     * @returns structs_ErrorResponse
     * @throws ApiError
     */
    public static postMunicipalityDashboardPageAddGoal(
        addGoalRequest: endpoints_MunicipalityDashboardPage_AddGoalRequest
    ): CancelablePromise<
        | endpoints_MunicipalityDashboardPage_AddGoalResponse
        | structs_ErrorResponse
    > {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/municipalityDashboardPage/addGoal',
            body: addGoalRequest,
        });
    }

    /**
     * Add Strategy
     * This will add a new strategy
     * @param addStrategyRequest Add Strategy Request Parameters
     * @returns endpoints_MunicipalityDashboardPage_AddStrategyResponse OK
     * @returns structs_ErrorResponse
     * @throws ApiError
     */
    public static postMunicipalityDashboardPageAddStrategy(
        addStrategyRequest: endpoints_MunicipalityDashboardPage_AddStrategyRequest
    ): CancelablePromise<
        | endpoints_MunicipalityDashboardPage_AddStrategyResponse
        | structs_ErrorResponse
    > {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/municipalityDashboardPage/addStrategy',
            body: addStrategyRequest,
        });
    }

    /**
     * Delete Action
     * This will delete an action
     * @param deleteActionRequest Delete Action Request Parameters
     * @returns endpoints_MunicipalityDashboardPage_DeleteActionResponse OK
     * @returns structs_ErrorResponse
     * @throws ApiError
     */
    public static deleteMunicipalityDashboardPageDeleteAction(
        deleteActionRequest: endpoints_MunicipalityDashboardPage_DeleteActionRequest
    ): CancelablePromise<
        | endpoints_MunicipalityDashboardPage_DeleteActionResponse
        | structs_ErrorResponse
    > {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/municipalityDashboardPage/deleteAction',
            body: deleteActionRequest,
        });
    }

    /**
     * Delete Goal
     * This will delete a goal
     * @param deleteGoalRequest Delete Goal Request Parameters
     * @returns endpoints_MunicipalityDashboardPage_DeleteGoalResponse OK
     * @returns structs_ErrorResponse
     * @throws ApiError
     */
    public static deleteMunicipalityDashboardPageDeleteGoal(
        deleteGoalRequest: endpoints_MunicipalityDashboardPage_DeleteGoalRequest
    ): CancelablePromise<
        | endpoints_MunicipalityDashboardPage_DeleteGoalResponse
        | structs_ErrorResponse
    > {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/municipalityDashboardPage/deleteGoal',
            body: deleteGoalRequest,
        });
    }

    /**
     * Delete Strategy
     * This will delete a strategy
     * @param deleteStrategyRequest Delete Strategy Request Parameters
     * @returns endpoints_MunicipalityDashboardPage_DeleteStrategyResponse OK
     * @returns structs_ErrorResponse
     * @throws ApiError
     */
    public static deleteMunicipalityDashboardPageDeleteStrategy(
        deleteStrategyRequest: endpoints_MunicipalityDashboardPage_DeleteStrategyRequest
    ): CancelablePromise<
        | endpoints_MunicipalityDashboardPage_DeleteStrategyResponse
        | structs_ErrorResponse
    > {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/municipalityDashboardPage/deleteStrategy',
            body: deleteStrategyRequest,
        });
    }

    /**
     * Get Goals Table Data
     * This will return the data for the goals table
     * @param municipalityId
     * @param numRows
     * @param pageNumber
     * @param searchQuery
     * @param sortDirection
     * @param sortHeader
     * @returns endpoints_MunicipalityDashboardPage_GoalsTableResponse OK
     * @returns structs_ErrorResponse
     * @throws ApiError
     */
    public static getMunicipalityDashboardPageGoalsTable(
        municipalityId: number,
        numRows?: number,
        pageNumber?: number,
        searchQuery?: string,
        sortDirection?: 'asc' | 'desc',
        sortHeader?: string
    ): CancelablePromise<
        | endpoints_MunicipalityDashboardPage_GoalsTableResponse
        | structs_ErrorResponse
    > {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/municipalityDashboardPage/goalsTable',
            query: {
                municipalityID: municipalityId,
                numRows: numRows,
                pageNumber: pageNumber,
                searchQuery: searchQuery,
                sortDirection: sortDirection,
                sortHeader: sortHeader,
            },
        });
    }

    /**
     * Get Strategies Table Data
     * This will return the data for the strategies table
     * @param municipalityId
     * @param numRows
     * @param pageNumber
     * @param searchQuery
     * @param sortDirection
     * @param sortHeader
     * @returns endpoints_MunicipalityDashboardPage_StrategiesTableResponse OK
     * @returns structs_ErrorResponse
     * @throws ApiError
     */
    public static getMunicipalityDashboardPageStrategiesTable(
        municipalityId: number,
        numRows?: number,
        pageNumber?: number,
        searchQuery?: string,
        sortDirection?: 'asc' | 'desc',
        sortHeader?: string
    ): CancelablePromise<
        | endpoints_MunicipalityDashboardPage_StrategiesTableResponse
        | structs_ErrorResponse
    > {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/municipalityDashboardPage/strategiesTable',
            query: {
                municipalityID: municipalityId,
                numRows: numRows,
                pageNumber: pageNumber,
                searchQuery: searchQuery,
                sortDirection: sortDirection,
                sortHeader: sortHeader,
            },
        });
    }

    /**
     * Update Action
     * This will update an action
     * @param updateActionRequest Update Action Request Parameters
     * @returns endpoints_MunicipalityDashboardPage_UpdateActionResponse OK
     * @returns structs_ErrorResponse
     * @throws ApiError
     */
    public static postMunicipalityDashboardPageUpdateAction(
        updateActionRequest: endpoints_MunicipalityDashboardPage_UpdateActionRequest
    ): CancelablePromise<
        | endpoints_MunicipalityDashboardPage_UpdateActionResponse
        | structs_ErrorResponse
    > {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/municipalityDashboardPage/updateAction',
            body: updateActionRequest,
        });
    }

    /**
     * Update Goal
     * This will update a goal
     * @param updateGoalRequest Update Goal Request Parameters
     * @returns endpoints_MunicipalityDashboardPage_UpdateGoalResponse OK
     * @returns structs_ErrorResponse
     * @throws ApiError
     */
    public static postMunicipalityDashboardPageUpdateGoal(
        updateGoalRequest: endpoints_MunicipalityDashboardPage_UpdateGoalRequest
    ): CancelablePromise<
        | endpoints_MunicipalityDashboardPage_UpdateGoalResponse
        | structs_ErrorResponse
    > {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/municipalityDashboardPage/updateGoal',
            body: updateGoalRequest,
        });
    }

    /**
     * Update Strategy
     * This will update a strategy
     * @param updateStrategyRequest Update Strategy Request Parameters
     * @returns endpoints_MunicipalityDashboardPage_UpdateStrategyResponse OK
     * @returns structs_ErrorResponse
     * @throws ApiError
     */
    public static postMunicipalityDashboardPageUpdateStrategy(
        updateStrategyRequest: endpoints_MunicipalityDashboardPage_UpdateStrategyRequest
    ): CancelablePromise<
        | endpoints_MunicipalityDashboardPage_UpdateStrategyResponse
        | structs_ErrorResponse
    > {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/municipalityDashboardPage/updateStrategy',
            body: updateStrategyRequest,
        });
    }
}
