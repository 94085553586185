import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import Hook from './useColorSelect';

interface IColorSelectProps {
    color: string;
    onChange: (event: SelectChangeEvent) => void;
}

function ColorSelect(props: IColorSelectProps) {
    const { color, onChange } = props;
    const { getColorName, isInColorNames, colorOptions } =
        Hook.useColorSelect();
    function renderMenuItem(color: string) {
        return (
            <MenuItem value={color} key={color}>
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                >
                    <Box
                        color={color}
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        pr={1}
                    >
                        <CircleIcon />
                    </Box>
                    {getColorName(color)}
                </Box>
            </MenuItem>
        );
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="color-select-label">Color</InputLabel>
            <Select
                labelId="color-select-label"
                id="color-select"
                value={color}
                label="Color"
                onChange={onChange}
            >
                {colorOptions.map(color => renderMenuItem(color))}
                {isInColorNames(color) ? null : renderMenuItem(color)}
            </Select>
        </FormControl>
    );
}

export default ColorSelect;
