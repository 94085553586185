import _ from 'lodash';
import {
    databaseModels_MunicipalityGoal,
    DefaultService,
} from '../../../../api-autogenerated';
import generalUtil from '../../../../utils/generalUtil/generalUtil';

const addEditModalStateReducer = (
    state: {
        title: 'Add Action' | 'Edit Action' | null;
        actionID: number | null;
        actionDescription: string;
    },
    action:
        | {
              type: 'setModalType';
              payload: {
                  actionID: number | null;
                  actionDescription: string;
              };
          }
        | {
              type: 'setActionDescription';
              payload: { actionDescription: string };
          }
): {
    title: 'Add Action' | 'Edit Action' | null;
    actionID: number | null;
    actionDescription: string;
} => {
    let newState;
    switch (action.type) {
        case 'setModalType':
            newState = {
                title: action.payload.actionID
                    ? ('Edit Action' as const)
                    : ('Add Action' as const),
                actionID: action.payload.actionID,
                actionDescription: action.payload.actionDescription,
            };
            return _.isEqual(newState, state) ? state : newState;
        case 'setActionDescription':
            newState = {
                ...state,
                actionDescription: action.payload.actionDescription,
            };
            return _.isEqual(newState, state) ? state : newState;
        default:
            return state;
    }
};

const handleSave = async (
    actionID: number | null,
    actionDescription: string,
    goalIDs: number[],
    strategyIDs: number[],
    municipalityID: number | null
): Promise<boolean> => {
    if (!municipalityID) {
        return new Promise<boolean>(resolve => resolve(false));
    }
    return new Promise<boolean>(async (resolve, reject) => {
        if (actionID) {
            try {
                await DefaultService.postMunicipalityDashboardPageUpdateAction({
                    municipalityID,
                    actionID,
                    action: actionDescription,
                    goalIDs,
                    strategyIDs,
                });
                resolve(true);
            } catch (error) {
                console.error(error);
                resolve(false);
            }
        } else {
            try {
                await DefaultService.postMunicipalityDashboardPageAddAction({
                    municipalityID,
                    action: actionDescription,
                    goalIDs,
                    strategyIDs,
                });
                resolve(true);
            } catch (error) {
                console.error(error);
                resolve(false);
            }
        }
    });
};

const loadGoalOptions = async (
    municipalityID: number | null
): Promise<databaseModels_MunicipalityGoal[]> => {
    if (!municipalityID) {
        return new Promise<databaseModels_MunicipalityGoal[]>(resolve =>
            resolve([])
        );
    }
    return new Promise<databaseModels_MunicipalityGoal[]>(
        async (resolve, reject) => {
            try {
                const goalsReturn =
                    await DefaultService.getMunicipalityDashboardPageGoalsTable(
                        municipalityID
                    );
                if (generalUtil.isError(goalsReturn)) {
                    resolve([]);
                } else if (!goalsReturn.goals) {
                    resolve([]);
                } else {
                    resolve(goalsReturn.goals);
                }
            } catch (error) {
                console.error(error);
                resolve([]);
            }
        }
    );
};

const loadStrategyOptions = async (
    municipalityID: number | null
): Promise<databaseModels_MunicipalityGoal[]> => {
    if (!municipalityID) {
        return new Promise<databaseModels_MunicipalityGoal[]>(resolve =>
            resolve([])
        );
    }
    return new Promise<databaseModels_MunicipalityGoal[]>(
        async (resolve, reject) => {
            try {
                const strategiesReturn =
                    await DefaultService.getMunicipalityDashboardPageStrategiesTable(
                        municipalityID
                    );
                if (generalUtil.isError(strategiesReturn)) {
                    resolve([]);
                } else if (!strategiesReturn.strategies) {
                    resolve([]);
                } else {
                    resolve(strategiesReturn.strategies);
                }
            } catch (error) {
                console.error(error);
                resolve([]);
            }
        }
    );
};

const helper = {
    addEditModalStateReducer,
    handleSave,
    loadGoalOptions,
    loadStrategyOptions,
};

export default helper;
