import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { IUseAddEditMunicipalityModalProps } from './useAddEditMunicipalityModal';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Hook from './useAddEditMunicipalityModal';
import HiddenFileUploadInput from '../../../../components/HiddenFileUploadInput/HiddenFileUploadInput';
import generalUtil from '../../../../utils/generalUtil/generalUtil';

interface IAddEditMunicipalityModalProps
    extends IUseAddEditMunicipalityModalProps {}

function AddEditMunicipalityModal(props: IAddEditMunicipalityModalProps) {
    const {
        handleCloseModal,
        municipalityId,
        municipalityName,
        province_state,
    } = props;
    const useAddEditMunicipalityModalProps = {
        handleCloseModal,
        municipalityId,
        municipalityName,
        province_state,
    };
    const {
        createFromCSV,
        addEditModalState,
        csvFileState,
        loading,
        handleChangeName,
        handleClickConfirm,
        handleToggleCSVCheckbox,
        handleAttachCSVFile,
        handleChangeProvinceState,
    } = Hook.useAddEditMunicipalityModal(useAddEditMunicipalityModalProps);

    return (
        <Modal open={true} onClose={handleCloseModal}>
            <Container maxWidth="sm">
                <Box bgcolor={'background.paper'}>
                    <Grid container padding={4}>
                        <Grid item xs={3}></Grid>
                        <Grid
                            item
                            xs={6}
                            display={'flex'}
                            justifyContent={'center'}
                        >
                            <Typography variant="h5">
                                {addEditModalState.title}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            display={'flex'}
                            justifyContent={'flex-end'}
                        >
                            <Button onClick={handleCloseModal}> X </Button>
                        </Grid>
                        <TextField
                            fullWidth
                            label={'Municipality Name'}
                            value={addEditModalState.municipalityName}
                            onChange={handleChangeName}
                        />
                        <FormControl fullWidth>
                            <InputLabel id="province_state_label">
                                Province/State
                            </InputLabel>
                            <Select
                                labelId="province_state_label"
                                value={addEditModalState.province_state}
                                label="Province/State"
                                onChange={handleChangeProvinceState}
                            >
                                {generalUtil.provinceStateOptions.map(
                                    provinceState => (
                                        <MenuItem
                                            key={provinceState}
                                            value={provinceState}
                                        >
                                            {provinceState}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                        {!municipalityId && (
                            <Grid
                                container
                                display={'flex'}
                                justifyContent={'Space-between'}
                            >
                                <Grid
                                    item
                                    xs={6}
                                    display={'flex'}
                                    alignItems={'center'}
                                >
                                    <Checkbox
                                        checked={createFromCSV}
                                        onChange={handleToggleCSVCheckbox}
                                    />
                                    <Typography>
                                        Create Customer from CSV
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'flex-end'}
                                >
                                    <Button
                                        component="label"
                                        disabled={!createFromCSV}
                                    >
                                        <Box
                                            pr={1}
                                            display={'flex'}
                                            alignItems={'center'}
                                        >
                                            <CloudUploadIcon />
                                        </Box>
                                        <Typography>
                                            {csvFileState.fileName ||
                                                'Attach CSV File'}
                                        </Typography>
                                        <HiddenFileUploadInput
                                            handleFileChange={
                                                handleAttachCSVFile
                                            }
                                        />
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                        <Button onClick={handleClickConfirm}>
                            {loading ? (
                                <Box
                                    pr={1}
                                    display={'flex'}
                                    alignItems={'center'}
                                >
                                    <CircularProgress size={'24px'} />
                                </Box>
                            ) : null}
                            <Typography>Confirm</Typography>
                        </Button>
                    </Grid>
                </Box>
            </Container>
        </Modal>
    );
}

export default AddEditMunicipalityModal;
