import { useCallback } from 'react';

function useAdminBackBar() {
    const backButtonClicked = useCallback(() => {
        console.log('Back button clicked');
    }, []);
    return { backButtonClicked };
}

const Hook = {
    useAdminBackBar,
};

export default Hook;
