import _ from 'lodash';
import { DefaultService } from '../../../../api-autogenerated';

const addEditModalStateReducer = (
    state: {
        title: 'Add Strategy' | 'Edit Strategy' | null;
        strategyID: number | null;
        strategyDescription: string;
        strategyColor: string;
    },
    action:
        | {
              type: 'setModalType';
              payload: {
                  strategyID: number | null;
                  strategyDescription: string;
                  strategyColor: string;
              };
          }
        | {
              type: 'setStrategyDescription';
              payload: { strategyDescription: string };
          }
        | { type: 'setStrategyColor'; payload: { strategyColor: string } }
): {
    title: 'Add Strategy' | 'Edit Strategy' | null;
    strategyID: number | null;
    strategyDescription: string;
    strategyColor: string;
} => {
    let newState;
    switch (action.type) {
        case 'setModalType':
            newState = {
                title: action.payload.strategyID
                    ? ('Edit Strategy' as const)
                    : ('Add Strategy' as const),
                strategyID: action.payload.strategyID,
                strategyDescription: action.payload.strategyDescription,
                strategyColor: action.payload.strategyColor,
            };
            return _.isEqual(newState, state) ? state : newState;
        case 'setStrategyDescription':
            newState = {
                ...state,
                strategyDescription: action.payload.strategyDescription,
            };
            return _.isEqual(newState, state) ? state : newState;
        case 'setStrategyColor':
            newState = {
                ...state,
                strategyColor: action.payload.strategyColor,
            };
            return _.isEqual(newState, state) ? state : newState;
        default:
            return state;
    }
};

const handleSave = async (
    strategyID: number | null,
    strategyDescription: string,
    strategyColor: string,
    municipalityID: number | null
): Promise<boolean> => {
    if (!municipalityID) {
        return new Promise<boolean>(resolve => resolve(false));
    }
    return new Promise<boolean>(async (resolve, reject) => {
        if (strategyID) {
            try {
                await DefaultService.postMunicipalityDashboardPageUpdateStrategy(
                    {
                        municipalityID,
                        strategyID,
                        strategy: strategyDescription,
                        color: strategyColor,
                    }
                );
                resolve(true);
            } catch (error) {
                console.error(error);
                resolve(false);
            }
        } else {
            try {
                await DefaultService.postMunicipalityDashboardPageAddStrategy({
                    municipalityID,
                    strategy: strategyDescription,
                    color: strategyColor,
                });
                resolve(true);
            } catch (error) {
                console.error(error);
                resolve(false);
            }
        }
    });
};

const helper = {
    addEditModalStateReducer,
    handleSave,
};

export default helper;
