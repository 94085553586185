import {
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { IUseAddEditActionModalProps } from './useAddEditActionModal';
import Hook from './useAddEditActionModal';

interface IAddEditActionModalProps extends IUseAddEditActionModalProps {}

function AddEditActionModal(props: IAddEditActionModalProps) {
    const {
        handleCloseModal,
        actionID,
        actionDescription,
        goalIDs,
        strategyIDs,
    } = props;
    const useAddEditActionModalProps = {
        handleCloseModal,
        actionID,
        actionDescription,
        goalIDs,
        strategyIDs,
    };
    const {
        addEditModalState,
        goalOptions,
        strategyOptions,
        selectedGoalIDs,
        selectedStrategyIDs,
        handleChangeActionDescription,
        handleClickConfirm,
        handleClickGoal,
        handleClickStrategy,
    } = Hook.useAddEditActionModal(useAddEditActionModalProps);

    const renderGoalOptionsDropdown = () => {
        return (
            <FormControl fullWidth>
                <InputLabel>Goals</InputLabel>
                <Select
                    multiple
                    id="goalsDropdown"
                    value={selectedGoalIDs}
                    onChange={handleClickGoal}
                    label="Goals"
                >
                    {goalOptions.map(
                        goal =>
                            goal.id && (
                                <MenuItem key={goal.id} value={goal.id}>
                                    {selectedGoalIDs.includes(goal.id) ? (
                                        <b>{goal.goal}</b>
                                    ) : (
                                        goal.goal
                                    )}
                                </MenuItem>
                            )
                    )}
                </Select>
            </FormControl>
        );
    };

    const renderStrategyOptionsDropdown = () => {
        return (
            <FormControl fullWidth>
                <InputLabel>Strategies</InputLabel>
                <Select
                    multiple
                    id="strategiesDropdown"
                    value={selectedStrategyIDs}
                    onChange={handleClickStrategy}
                    label="Strategies"
                >
                    {strategyOptions.map(
                        strategy =>
                            strategy.id && (
                                <MenuItem key={strategy.id} value={strategy.id}>
                                    {selectedStrategyIDs.includes(
                                        strategy.id
                                    ) ? (
                                        <b>{strategy.strategy}</b>
                                    ) : (
                                        strategy.strategy
                                    )}
                                </MenuItem>
                            )
                    )}
                </Select>
            </FormControl>
        );
    };

    return (
        <Modal open={true} onClose={handleCloseModal}>
            <Container maxWidth="sm">
                <Box bgcolor={'background.paper'}>
                    <Grid container padding={4}>
                        <Grid item xs={3}></Grid>
                        <Grid
                            item
                            xs={6}
                            display={'flex'}
                            justifyContent={'center'}
                        >
                            <Typography variant="h5">
                                {addEditModalState.title}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            display={'flex'}
                            justifyContent={'flex-end'}
                        >
                            <Button onClick={handleCloseModal}> X </Button>
                        </Grid>
                        <TextField
                            fullWidth
                            label={'Action Description'}
                            value={addEditModalState.actionDescription}
                            onChange={handleChangeActionDescription}
                        />
                        {renderGoalOptionsDropdown()}
                        {renderStrategyOptionsDropdown()}
                        <Button onClick={handleClickConfirm}> Confirm </Button>
                    </Grid>
                </Box>
            </Container>
        </Modal>
    );
}

export default AddEditActionModal;
