import { Container } from '@mui/material';
import { IUseAdminMunicipalitiesPageProps } from './useAdminMunicipalitiesPage';
import MunicipalitiesTable from './MunicipalitiesTable/MunicipalitiesTable';

export interface IAdminMunicipalitiesPageProps
    extends IUseAdminMunicipalitiesPageProps {}

function AdminMunicipalitiesPage() {
    return (
        <Container maxWidth="md">
            <MunicipalitiesTable />
        </Container>
    );
}

export default AdminMunicipalitiesPage;
