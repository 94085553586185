import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Box } from '@mui/material';
import './App.scss';
import AdminMunicipalitiesPage from '../pages/AdminMunicipalitiesPage/AdminMunicipalitiesPage';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import { RecoilRoot } from 'recoil';
import MunicipalityDashboardPage from '../pages/MunicipalityDashboardPage/MunicipalityDashboardPage';
import { Auth0Provider } from '@auth0/auth0-react';
import Hook from './useApp';

const router = createBrowserRouter([
    {
        path: '/',
        element: <AdminMunicipalitiesPage />,
    },
    {
        path: '/municipality/*',
        element: <MunicipalityDashboardPage />,
    },
    {
        path: '/*',
        element: <ErrorPage />,
    },
]);

function AuthContent() {
    const { isAuthenticated } = Hook.useApp();
    if (!isAuthenticated) {
        return <></>;
    } else {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{ height: '100vh' }}
            >
                <RouterProvider router={router} />
            </Box>
        );
    }
}

function App() {
    return (
        <RecoilRoot>
            <Auth0Provider
                domain={'climatebridge.us.auth0.com'}
                clientId={'t0XwbtldvTJ8pkPARa8EAJ1rN06hwftf'}
                authorizationParams={{
                    redirect_uri: window.location.origin,
                    audience: '/api',
                }}
                cacheLocation="localstorage"
            >
                <AuthContent />
            </Auth0Provider>
        </RecoilRoot>
    );
}

export default App;
