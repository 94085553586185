import { Box, Container, Tab, Tabs } from '@mui/material';
import Hook, {
    IUseMunicipalityDashboardPageProps,
} from './useMunicipalityDashboardPage';
import GoalsTab from './GoalsTab/GoalsTab';
import StrategiesTab from './StrategiesTab/StrategiesTab';
import ActionsTab from './ActionsTab/ActionsTab';
import AdminBackBar from '../../components/AdminBackBar/AdminBackBar';

export interface IMunicipalityDashboardPageProps
    extends IUseMunicipalityDashboardPageProps {}

function MunicipalityDashboardPage(props: IMunicipalityDashboardPageProps) {
    const useMunicipalityDashboardPageProps = {};
    const { tabState, handleChangeTab } = Hook.useMunicipalityDashboardPage(
        useMunicipalityDashboardPageProps
    );

    return (
        <>
            <AdminBackBar />
            <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={tabState}
                        onChange={handleChangeTab}
                        aria-label="basic tabs example"
                    >
                        <Tab label="@Goals" />
                        <Tab label="#Strategies" />
                        <Tab label="Actions" />
                    </Tabs>
                </Box>
                {tabState === 0 && <GoalsTab />}
                {tabState === 1 && <StrategiesTab />}
                {tabState === 2 && <ActionsTab />}
            </Container>
        </>
    );
}

export default MunicipalityDashboardPage;
