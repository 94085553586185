import { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import helper from './addEditActionModalHelper';
import { useRecoilValue } from 'recoil';
import { atomMunicipalityID } from '../../../../state/atoms';
import {
    databaseModels_MunicipalityGoal,
    databaseModels_MunicipalityStrategy,
} from '../../../../api-autogenerated';
import { SelectChangeEvent } from '@mui/material';

export interface IUseAddEditActionModalProps {
    handleCloseModal: () => void;
    actionID: number | null;
    actionDescription: string;
    goalIDs: number[];
    strategyIDs: number[];
}

function useAddEditActionModal(props: IUseAddEditActionModalProps) {
    const {
        handleCloseModal,
        actionID,
        actionDescription,
        goalIDs,
        strategyIDs,
    } = props;
    const { t } = useTranslation();
    const [addEditModalState, dispatchAddEditModalState] = useReducer(
        helper.addEditModalStateReducer,
        {
            title: null,
            actionID: null,
            actionDescription: '',
        }
    );
    const municipalityID = useRecoilValue(atomMunicipalityID);
    const [goalOptions, setGoalOptions] = useState<
        databaseModels_MunicipalityGoal[]
    >([]);
    const [strategyOptions, setStrategyOptions] = useState<
        databaseModels_MunicipalityStrategy[]
    >([]);
    const [selectedGoalIDs, setSelectedGoalIDs] = useState<number[]>(goalIDs);
    const [selectedStrategyIDs, setSelectedStrategyIDs] =
        useState<number[]>(strategyIDs);

    // Callbacks
    const handleChangeActionDescription = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            dispatchAddEditModalState({
                type: 'setActionDescription',
                payload: { actionDescription: event.target.value },
            });
        },
        [dispatchAddEditModalState]
    );

    const handleClickConfirm = useCallback(async () => {
        const result = await helper.handleSave(
            actionID,
            addEditModalState.actionDescription,
            selectedGoalIDs,
            selectedStrategyIDs,
            municipalityID
        );
        if (result) {
            handleCloseModal();
        }
    }, [
        actionID,
        addEditModalState.actionDescription,
        selectedGoalIDs,
        selectedStrategyIDs,
        municipalityID,
        handleCloseModal,
    ]);

    const loadGoalOptions = useCallback(async () => {
        const goals = await helper.loadGoalOptions(municipalityID);
        setGoalOptions(goals);
    }, [municipalityID, setGoalOptions]);

    const loadStrategyOptions = useCallback(async () => {
        const strategies = await helper.loadStrategyOptions(municipalityID);
        setStrategyOptions(strategies);
    }, [municipalityID, setStrategyOptions]);

    const handleClickGoal = useCallback(
        (event: SelectChangeEvent<number[]>) => {
            setSelectedGoalIDs(event.target.value as number[]);
        },
        [setSelectedGoalIDs]
    );

    const handleClickStrategy = useCallback(
        (event: SelectChangeEvent<number[]>) => {
            setSelectedStrategyIDs(event.target.value as number[]);
        },
        [setSelectedStrategyIDs]
    );

    // UseEffects
    useEffect(() => {
        dispatchAddEditModalState({
            type: 'setModalType',
            payload: { actionID, actionDescription },
        });
    }, [dispatchAddEditModalState, actionID, actionDescription]);

    useEffect(() => {
        loadGoalOptions();
    }, [loadGoalOptions]);

    useEffect(() => {
        loadStrategyOptions();
    }, [loadStrategyOptions]);

    // Return
    return {
        t,
        addEditModalState,
        goalOptions,
        strategyOptions,
        selectedGoalIDs,
        selectedStrategyIDs,
        handleChangeActionDescription,
        handleClickConfirm,
        handleClickGoal,
        handleClickStrategy,
    };
}

const Hook = {
    useAddEditActionModal,
};

export default Hook;
