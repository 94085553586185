import { ChangeEvent, MouseEvent } from 'react';
import {
    TTableArgs,
    TTableColumnHeaders,
    TTableColumnSortOptions,
    TTableColumnValues,
} from '../../typescript/ITCommon';
import { every, includes } from 'lodash';

const handleOnChangeSort = (
    event: MouseEvent<HTMLSpanElement>,
    tableState: TTableArgs
): {
    sortHeader: string | undefined;
    sortDirection: TTableColumnSortOptions | undefined;
} => {
    const clickedColumnID = event.currentTarget.getAttribute('data-columnid');
    let sortDirection: TTableColumnSortOptions = 'asc';
    if (!clickedColumnID) {
        return {
            sortHeader: tableState.sortHeader,
            sortDirection: tableState.sortDirection,
        };
    }
    if (tableState.sortHeader === clickedColumnID) {
        sortDirection = tableState.sortDirection === 'asc' ? 'desc' : 'asc';
    }
    return {
        sortHeader: clickedColumnID,
        sortDirection: sortDirection,
    };
};

const handleOnChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    return parseInt(event.target.value, 10);
};

const tableDataValidation = (
    columnHeaders: TTableColumnHeaders,
    sortableColumnHeaderIDs: string[],
    rowsObjects: TTableColumnValues
) => {
    // Confirm sortableColumnHeaderIDs are in columnHeaders
    const columnIds = columnHeaders.map(column => column.id);
    const isValidSortableColumnHeaderIDs = every(sortableColumnHeaderIDs, id =>
        includes(columnIds, id)
    );
    // Confirm rowsObjects have the same keys as columnHeaders
    const isValidRows = every(rowsObjects, row => {
        const rowKeys = Object.keys(row);
        return every(columnIds, columnId => includes(rowKeys, columnId));
    });
    const isValidData = isValidSortableColumnHeaderIDs && isValidRows;
    if (!isValidData) {
        console.error('Invalid table row data');
    }
};

const helper = {
    handleOnChangeSort,
    handleOnChangeRowsPerPage,
    tableDataValidation,
};

export default helper;
