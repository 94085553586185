import { DefaultService } from '../../../../api-autogenerated';

const confirmDelete = async (
    token: string | null,
    municipalityID: number | null
): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        if (!municipalityID || !token) {
            resolve(false);
            return;
        }
        try {
            DefaultService.deleteAdminMunicipalitiesPageDeleteMunicipality(
                {
                    id: municipalityID,
                },
                token
            );
            resolve(true);
        } catch (error) {
            resolve(false);
        }
    });
};

const helper = { confirmDelete };

export default helper;
