import { Box, Button, Container, Grid, Modal, Typography } from '@mui/material';
import { IUseDeleteGoalModalProps } from './useDeleteGoalModal';
import Hook from './useDeleteGoalModal';

interface IDeleteGoalModalProps extends IUseDeleteGoalModalProps {
    goalDescription: string;
}

function DeleteGoalModal(props: IDeleteGoalModalProps) {
    const { handleCloseModal, goalID, goalDescription } = props;
    const useDeleteGoalModalProps = {
        handleCloseModal,
        goalID,
    };
    const { handleClickConfirm } = Hook.useDeleteGoalModal(
        useDeleteGoalModalProps
    );

    return (
        <Modal open={true} onClose={handleCloseModal}>
            <Container maxWidth="sm">
                <Box bgcolor={'background.paper'}>
                    <Grid container padding={4}>
                        <Grid item xs={3}></Grid>
                        <Grid
                            item
                            xs={6}
                            display={'flex'}
                            justifyContent={'center'}
                        >
                            <Typography variant="h5">
                                {'Delete Goal'}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            display={'flex'}
                            justifyContent={'flex-end'}
                        >
                            <Button onClick={handleCloseModal}> X </Button>
                        </Grid>
                        <Typography variant="h6">
                            {`Are you sure you want to delete ${goalDescription}?`}
                        </Typography>
                        <Button onClick={handleClickConfirm}> Confirm </Button>
                    </Grid>
                </Box>
            </Container>
        </Modal>
    );
}

export default DeleteGoalModal;
