import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import helper from './deleteStrategyModalHelper';
import { useRecoilValue } from 'recoil';
import { atomMunicipalityID } from '../../../../state/atoms';

export interface IUseDeleteStrategyModalProps {
    handleCloseModal: (_: React.MouseEvent<HTMLElement>) => void;
    strategyID: number | null;
}

function useDeleteStrategyModal(props: IUseDeleteStrategyModalProps) {
    const { handleCloseModal, strategyID } = props;
    const { t } = useTranslation();
    const municipalityID = useRecoilValue(atomMunicipalityID);

    // Props

    // State

    // Callbacks

    const handleClickConfirm = useCallback(
        async (event: React.MouseEvent<HTMLElement>) => {
            const result = await helper.confirmDelete(
                strategyID,
                municipalityID
            );
            if (result) {
                handleCloseModal(event);
            }
        },
        [strategyID, municipalityID, handleCloseModal]
    );

    // UseEffects

    // Return
    return {
        t,
        handleClickConfirm,
    };
}

const Hook = {
    useDeleteStrategyModal,
};

export default Hook;
